* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
}

.skills-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.skills-container .header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.skills-container .logo img {
  height: 65px;
  /* width: 150px; */
}

.skills-container .card {
  background: white;
  padding: 20px;
  border-radius: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 1120px;
  margin-top: 50px;
  height: 450px;
}

.skills-container .progress {
  font-size: 14px;
  margin-bottom: 20px;
}

.skills-container .card h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.skills-container .card p {
  color: #555;
  font-size: 14px;
  margin-bottom: 10px;
}

.skills-container .subtext a {
  color: #62495B;
  text-decoration: none;
  font-size: 12px;
}

.skills-container .skills-section {
  margin-top: 20px;
}

.skills-container .search-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
}

.skills-container .or-text {
  display: block;
  text-align: center;
  margin: 10px 0;
  color: #999;
}

.skills-container .skills-selector {
  display: flex;
  justify-content: space-between;
}

.skills-container .categories {
  width: 30%;
  height: 150px;
  overflow-y: auto;
}

.skills-container .category-item {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 5px;
  cursor: pointer;
}

.skills-container .category-item.active {
  background-color: #62495B;
  color: white;
}

.skills-container .skills-list {
  width: 30%;
  height: 150px;
  overflow-y: auto;
}

.skills-container .skill-item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 5px;
}

/* .skills-container .selected-skills {
    width: 30%;
    border: 1px dashed #ddd;
    padding: 10px;
    border-radius: 4px;
    height: 150px;
    overflow-y: auto;
  } */

/* .skills-container .selected-skill-item {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 5px;
    background-color: black;
    color: white;
  } */

.skills-container .footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 80px;
}

.skills-container .footer .back-btn,
.skills-container .footer .next-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
}

.skills-container .footer .back-btn {
  background-color: #ffffff;
  color: black;
  border: 1px black solid;
}

.skills-container .footer .next-btn {
  background-color: #62495B;
  color: #fff;
}

.skills-container .add-button {
  height: 30px;
  width: 30px;
  padding: initial;
  border: none;
  background-color: white;
}

.skills-container .remove-button {
  height: 30px;
  width: 30px;
  padding: initial;
  border: none;
  border-radius: 50%;
}


.selected-skills {
  width: 30%;
  border: 2px dashed #62495B;
  padding: 10px;
  border-radius: 4px;
  height: 150px;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}

.selected-skill-item {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 20px;
  background-color: #000;
  color: #fff;
  font-size: 14px;
}

.selected-skill-item span {
  margin-right: 10px;
}

.selected-skill-item .remove-button {
  height: 20px;
  width: 20px;
  padding: initial;
  border: none;
  border-radius: 50%;
  background-color: #fff;
  color: #000;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}