* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
}

.tos-container .logo img {
    height: 65px;
}

.tos-container .main-contents {
    width: 1120px;
    height: 450px;
    padding: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 30px;
    border: 1px rgb(192, 192, 192) solid;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.tos-container .content {
    margin-top: 30px;
    padding: 0px 20px;
}

/* ================ */

.tos-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-top: 20px;
}

.tos-container .header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.main-contents {
    flex: 1;
    padding: 20px;
    background-color: #ffffff;
}

.tos-container .main-contents h1 {
    font-size: 24px;
    font-weight: 700;
    color: #333333;
}

.tos-container .main-contents h2 {
    font-size: 20px;
    font-weight: 500;
    color: #333333;
}

.tos-container .main-contents p,
.tos-container .main-contents ul {
    color: #666666;
}

.tos-container .main-contents ul {
    list-style-type: disc;
    padding-left: 20px;
}