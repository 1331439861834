.complete-profile-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.complete-profile-popup-overlay .complete-profile-popup-content {
    background-color: #fff;
    border-radius: 10px;
    max-width: 800px;
    width: 90%;
    display: flex;
    overflow: hidden;
}

.complete-profile-popup-overlay .profile-left {
    background-color: #f7f7f7;
    width: 40%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.complete-profile-popup-overlay .profile-image {
    border-radius: 50%;
    width: 170px;
    height: 170px;
    object-fit: cover;
    margin-bottom: 15px;
}

.complete-profile-popup-overlay .completion-status {
    margin-top: 20px;
}

.complete-profile-popup-overlay .completion-percentage {
    font-size: 16px;
    color: #62495B;
}

.complete-profile-popup-overlay .completion-text {
    font-size: 24px;
}

.complete-profile-popup-overlay .profile-right {
    padding: 20px;
    width: 60%;
    position: relative;
}

.complete-profile-popup-overlay .close {
    position: absolute;
    right: 10px;
    top: 5px;
    color: #000;
    font-size: 30px;
}

.complete-profile-popup-overlay .profile-right h2 {
    font-size: 22px;
    margin-bottom: 10px;
    color: #6b4b79;
}

.complete-profile-popup-overlay .profile-right p {
    font-size: 14px;
    /* margin-bottom: 20px; */
    color: #1B1919;
}

.complete-profile-popup-overlay .profile-sections {
    list-style: none;
    padding: 0;
    margin: 0;
}

.complete-profile-popup-overlay .section-item {
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
    border-bottom: 1px solid #e0e0e0;
    cursor: pointer;
}

.complete-profile-popup-overlay .section-item-heading {
    display: flex;
    flex-direction: column;
}

/* .section-item.completed {
    opacity: 0.8;
} */

.complete-profile-popup-overlay .section-name {
    font-size: 16px;
    font-weight: 600;
}

.complete-profile-popup-overlay .section-description {
    font-size: 12px;
    color: #A3A3A3;
    /* margin-left: 10px; */
    flex: 1;
}

.complete-profile-popup-overlay .section-arrow {
    font-size: 20px;
    color: #62495B;
}

.complete-profile-popup-overlay .completed-sections {
    margin-top: 20px;
    margin-bottom: 50px;
}

.complete-profile-popup-overlay .toggle-completed {
    background: none;
    border: none;
    color: #62495B;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 10px;
    font-weight: 600;
}

.complete-profile-popup-overlay .completed-note {
    font-size: 14px;
    margin-bottom: 10px;
}

.complete-profile-popup-overlay .close-button {
    background-color: #62495B;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    position: absolute;
    bottom: 15px;
    right: 20px;
}