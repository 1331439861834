 body {
   margin: 0;
   font-family: 'Lato', sans-serif;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   /* height: 100vh; */
   /* background-color: #f0f0f0; */
 }

 .container {
   text-align: center;
   width: 100%;
   max-width: 400px;
 }

 .header {
   margin-bottom: 20px;
 }

 .logo {
   height: 100px;
 }

 .card {
   background: white;
   padding: 20px;
   border-radius: 8px;
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
   height: 450px;
   width: 500px;
 }

 .card h2 {
   margin: 0 0 20px;
 }

 .cardMessage {
   color: #555;
   font-size: 14px;
   margin-bottom: 20px;
 }

 .form {
   text-align: left;
 }

 .formLabel {
   display: block;
   margin-bottom: 5px;
   color: #333;
 }

 .formInput {
   width: 100%;
   padding: 10px;
   margin-bottom: 20px;
   border: 1px solid #ddd;
   border-radius: 4px;
   box-sizing: border-box;
 }

 .buttons {
   display: flex;
   justify-content: space-between;
 }

 .cancelButton,
 .resetButton {
   padding: 10px 20px;
   border: none;
   border-radius: 30px;
   cursor: pointer;
 }

 .cancelButton {
   background-color: #e0e0e0;
   color: #333;
 }

 .resetButton {
   background-color: #62495B;
   color: #fff;
 }

 .resetButton:hover {
   background-color: #62495B;
 }

 .footer {
   margin-top: 20px;
 }

 .footerText {
   font-size: 12px;
   color: #888;
 }

 .footerLink {
   color: #62495B;
   text-decoration: none;
 }