.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1101;
  }
  
  .popup-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .popup-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  
  .popup-button-cancel {
    background: #ddd;
    border: none;
    padding: 10px 20px;
    border-radius: 30px;
    cursor: pointer;
  }
  
  .popup-button-save {
    background: #62495B;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 30px;
    cursor: pointer;
  }