* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
}

.resume-model {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

.resume-model .model-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 500px;
    text-align: center;
    position: relative;
}

.resume-model .close {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #aaa;
    font-size: 28px;
    font-weight: bold;
}

.resume-model .close:hover,
.resume-model .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.resume-model .image-placeholder {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.resume-model .image-placeholder img {
    max-width: 100%;
    height: 150px;
    margin-right: auto;
}

.resume-model .file-name-box {
    position: relative;
    display: flex;
    align-items: center;
    color: #62495B;
    padding: 6px 20px;
    border: 1px #62495B solid;
    border-radius: 30px;
    cursor: pointer;
    margin-top: 10px;
    width: 50%;
    height: 40px;
}

.resume-model .file-name-box p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    padding-right: 30px;
}

.resume-model .close-icon {
    margin-left: auto;
    position: absolute;
    right: 10px;
    font-size: 16px;
    color: #000;
    cursor: pointer;
}

.resume-model .upload-button {
    color: #62495B;
    padding: 10px 20px;
    border: 1px #62495B solid;
    border-radius: 30px;
    cursor: pointer;
    margin-top: 10px;
    margin-right: 50%;
    width: max-content;
}

.resume-model .upload-button:hover {
    background-color: #62495B;
    color: #fefefe;
}

.resume-model .continue-button {
    background-color: #62495B;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    margin-top: 20px;
}

.resume-model .continue-button:hover {
    background-color: #62495B;
}