* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
}

.job-listing-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.job-listing-container .main-container {
    display: flex;
    flex-direction: row;
    width: 1200px;
}

.job-listing-container .container-left {
    width: 65%;
}

.job-listing-container .profile-pic {
    height: 40px;
    width: 40px;
    border-radius: 50%;
}

.job-listing-container .intro-section {
    background-color: #62495B;
    color: white;
    border-radius: 20px;
    padding: 20px;
    margin: 20px 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.job-listing-container .intro-content h2 {
    font-size: 17px;
    margin-bottom: 10px;
}

.job-listing-container .intro-content h4 {
    font-size: 15px;
    margin-bottom: 10px;
}

.job-listing-container .intro-content button {
    background-color: #fff;
    color: #62495B;
    border: none;
    padding: 10px 10px;
    border-radius: 30px;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    margin-left: initial;
}


.job-listing-container .intro-image img {
    height: 100px;
    width: 115px;
    margin-left: 10%;
    border-radius: 20px;
}

.job-listing-container .search-section {
    margin: 20px 0;
    width: 100%;
    text-align: center;
}

.job-listing-container .search-section input {
    width: 100%;
    padding: 10px;
    border-radius: 30px;
    border: 1px solid #ccc;
}

.job-listing-container .job-listings {
    width: 100%;
}

.job-listing-container .job-listings h3 {
    font-size: 18px;
    margin-bottom: 10px;
}

.job-listing-container .job-card {
    background-color: #fff;
    padding: 20px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.job-listing-container .job-card:hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.job-listing-container .job-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.job-listing-container .job-header h4 {
    font-size: 16px;
    font-weight: bold;
}

.job-listing-container .bookmark-icon {
    cursor: pointer;
}

.job-listing-container .job-info {
    display: flex;
    gap: 20px;
    margin-bottom: 10px;
}

.job-listing-container .job-info span {
    font-size: 14px;
    color: #666;
}

.job-listing-container .job-skills {
    display: flex;
    gap: 10px;
}

.job-listing-container .job-skills .skill {
    background-color: #E3C2FE;
    padding: 5px 10px;
    border-radius: 30px;
    font-size: 14px;
    color: #333;
}

.job-listing-container .payment-verified {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #666;
    font-size: 14px;
    margin-top: 6px;
}

.job-listing-container .container-right {
    width: 30%;
    margin-left: 5%;
}

.job-listing-container .complete-profile {
    background-color: #f8f8f8;
    padding: 20px;
    margin: 20px 0;
    width: 100%;
    border-radius: 20px;
}

.job-listing-container .features {
    background-color: #f8f8f8;
    padding: 20px;
    margin: 20px 0;
    width: 100%;
    border-radius: 20px;
}

.job-listing-container .features p {
    font-weight: 600;
}

.job-listing-container .contract {
    background-color: #f8f8f8;
    padding: 20px;
    margin: 20px 0;
    width: 100%;
    border-radius: 20px;
}

/* .job-listing-container .footer {
    background-color: #333;
    color: #fff;
    padding: 20px;
    margin-top: 20px;
    width: 100%;
    text-align: center;
}

.job-listing-container .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.job-listing-container .footer-links a {
    text-decoration: none;
    color: #fff;
    margin: 0 10px;
} */