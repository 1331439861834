* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
}

.client-dashboard {
    width: 1200px;
    margin: auto;
    /* padding: 20px 0; */
}

.client-dashboard .dashboard-content {
    padding: 10px 20px;
}

.client-dashboard .add-project-button {
    padding: 8px 16px;
    background-color: #62495B;
    color: #fff;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    height: 40px;
    width: 180px;
}

.client-dashboard .user-info {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.client-dashboard .user-info h1 {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 10px;
}

.client-dashboard .user-info p {
    font-size: 16px;
    font-weight: 600;
}

.client-dashboard .projects-section,
.client-dashboard .reviews-section {
    margin: 30px 0;
}

.client-dashboard .projects-section h2 {
    font-size: 24px;
    font-weight: 700;
}

.client-dashboard .projects-section-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 10px;
}

.client-dashboard .sort-options {
    display: flex;
    align-items: center;
}

.client-dashboard .sort-options select {
    margin-left: 10px;
    padding: 8px 12px;
    border-radius: 30px;
}

.client-dashboard .projects-list {
    border-radius: 5px;
    padding: 20px;
    background-color: #fff;
}

.client-dashboard .project-card {
    display: flex;
    justify-content: space-between;
    padding: 15px 15px;
    border: 1px solid #ddd;
    border-radius: 20px;
    margin-bottom: 10px;
}

.client-dashboard .project-details {
    margin-bottom: 0px;
    width: 35%;
}

.client-dashboard .project-details h3 {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 600;
}

.client-dashboard .project-details p {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 5px;
    margin-top: 8px;
}

.client-dashboard .project-stats {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 36%;
    align-items: center;
}

.client-dashboard .project-stats .content {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 0px;
}

.client-dashboard .project-stats .heading {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 0px;
    color: #A3A3A3;
}

.client-dashboard .project-edit {
    display: flex;
    align-items: center;
    margin-right: 15px;
    cursor: pointer;
}

.client-dashboard .view-all-projects {
    padding: 10px;
    margin-top: 20px;
    border: 1px solid #62495B;
    background: none;
    color: #62495B;
    border-radius: 30px;
    cursor: pointer;
    height: 40px;
    width: 150px;
}

.client-dashboard .reviews-section h2 {
    font-size: 24px;
    font-weight: 700;
}

.client-dashboard .reviews-list {
    display: flex;
    gap: 20px;
    justify-content: space-around;
    margin-top: 30px;
}

.client-dashboard .review-card {
    background-color: #fff;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 20px;
    width: 352px;
    min-height: 350px;
}

.client-dashboard .review-header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    border-radius: 5px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
    background-color: #F3F3F3;
}

.client-dashboard .review-header h3 {
    font-size: 16px;
    font-weight: 600;
}

.client-dashboard .review-header p {
    font-size: 12px;
    font-weight: 500;
    /* color: #A3A3A3; */
}

.client-dashboard .review-header img {
    border-radius: 10px;
    height: 80px;
    width: 80px;
    margin-right: 10px;
}

.client-dashboard .review-body p {
    margin-top: 20px;
    font-size: 14px;
    font-weight: 600;
    text-align: justify;
}