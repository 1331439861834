.agency-account-container {
    width: 1150px;
    margin: 0 auto;
    font-family: 'Lato', sans-serif;
}

.agency-account-container .agency-account-main {
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-top: 30px;
    margin-bottom: 12%;
}

.agency-account-container .agency-account-main h1 {
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: 600;
}

.agency-account-container .agency-account-main p {
    font-size: 16px;
    margin-bottom: 20px;
    width: 90%;
}

.agency-account-container .agency-account-main p a {
    color: #000;
    text-decoration: underline;
}

.agency-account-container .form-group {
    margin-bottom: 20px;
}

.agency-account-container .form-group label {
    font-size: 16px;
    margin-bottom: 5px;
    display: block;
    font-weight: 600;
}

.agency-account-container .form-group input {
    width: 40%;
    padding: 10px;
    border-radius: 15px;
    border: 1px solid #ccc;
}

.agency-account-container .form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 40px;
}

.agency-account-container .cancel-button,
.agency-account-container .continue-button {
    padding: 10px 20px;
    border-radius: 30px;
    border: none;
    cursor: pointer;
    font-size: 16px;
}

.agency-account-container .cancel-button {
    background: none;
    color: #62495B;
}

.agency-account-container .continue-button {
    background-color: #62495B;
    color: #fff;
    margin-top: 0px;
}