.profile-section {
    width: 850px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
}

.profile-section .header-left {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.profile-section .header-left h2 {
    font-weight: 600;
    margin-bottom: 5px;
}

.profile-section .header-left a {
    font-size: 16px;
    color: #62495B;
    text-decoration: none;
}

.profile-section .profile-visibility {
    border: 1px solid #e0e0e0;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    background-color: #fff;
}

.profile-section .visibility-options {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    margin-top: 15px;
}

.profile-section .visibility-options label {
    font-size: 18px;
    font-weight: 600;
}

.profile-section .visibility-options select {
    padding: 5px 10px;
    border-radius: 15px;
    border: 1px solid #ccc;
    height: 40px;
    margin-top: 12px;
}

.profile-section .earnings-privacy h5 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
}

.profile-section .profile-section .earnings-privacy p {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0px;
}

.profile-section .experience-level {
    border: 1px solid #e0e0e0;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    background-color: #fff;
}

.profile-section .experience-level h3 {
    font-weight: 600;
}

.profile-section .experience-options {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 10px;
}

.profile-section .experience-option {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    border: 2px solid #ddd;
    border-radius: 30px;
    text-align: center;
    cursor: pointer;
    transition: border 0.3s;
}

.profile-section .experience-options label {
    width: 32%;
    border: 2px solid #ddd;
    border-radius: 20px;
}

.profile-section .experience-option input {
    margin-left: auto;
    width: auto;
}

.profile-section .experience-option .text {
    font-size: 16px;
    font-weight: bold;
}

.profile-section .experience-option .subtext {
    font-size: 12px;
    color: black;
}

.profile-section .categories-section {
    border: 1px solid #e0e0e0;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    background-color: #fff;
}

.profile-section .categories-section h3 {
    font-weight: 600;
}

.profile-section .categories-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 15px;
    margin-top: 25px;
}

.profile-section .category-tag {
    background-color: #000;
    color: #fff;
    padding: 10px;
    border-radius: 30px;
    font-size: 14px;
}

.profile-section .specialized-profiles {
    border: 1px solid #e0e0e0;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    background-color: #fff;
}

.profile-section .specialized-profiles h3 {
    font-weight: 600;
}

.profile-section .specialized-profiles p {
    font-size: 14px;
    color: #A3A3A3;
    font-weight: 600;
}

.profile-section .profile-list {
    margin-bottom: 15px;
}

.profile-section .profile-tag {
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profile-section .profile-actions button {
    margin-left: 10px;
    padding: 5px 10px;
    border: none;
    color: #62495B;
    cursor: pointer;
}

.profile-section .add-profile-button {
    padding: 10px 20px;
    border: 1px solid #62495B;
    border-radius: 30px;
    background-color: #fff;
    color: #62495B;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
}

.profile-section .linked-accounts {
    border: 1px solid #e0e0e0;
    padding: 20px;
    border-radius: 8px;
    background-color: #fff;
}

.profile-section .linked-accounts h3 {
    font-weight: 600;
}

.profile-section .account-list {
    display: flex;
    gap: 10px;
    margin-top: 30px;
}

.profile-section .account-tag {
    border: 1px solid #62495B;
    padding: 10px;
    border-radius: 30px;
    font-size: 14px;
    width: 350px;
    text-align: center;
}

.profile-section .edit-button {
    background-color: white;
    color: #62495B;
    border: none;
    padding: 5px 10px;
    border-radius: 50%;
    border: 0.5px #62495B solid;
    cursor: pointer;
    margin-top: 10px;
    height: 40px;
    width: 40px;
}

.profile-section .edit-btn-profile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.profile-section .edit-btn-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
}

.profile-section .edit-button:hover {
    background-color: black;
    color: #fff;
}