.user-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px 10px;
    background-color: #fff;
    border-bottom: 1px solid #eee;
    align-items: baseline;
}

.user-header nav {
    justify-content: center;
}

/* .user-header input {
    width: 25%;
    height: 10%;
    border-radius: 30px;
    margin-top: 5px;
    padding: 10px;
    border: 1px solid #ccc;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
} */

.user-header .logo img {
    height: 65px;
}

.user-header nav ul {
    display: flex;
    list-style: none;
    position: relative;
}

.user-header nav ul li {
    margin-right: 20px;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
}

.user-header .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    min-width: 150px;
}

.user-header .dropdown-item {
    padding: 10px 20px;
    cursor: pointer;
}

.user-header .dropdown-item:hover {
    background-color: #f5f5f5;
}

.user-header .search-container {
    position: relative;
    width: 300px;
}

.user-header .input-dropdown-wrapper {
    display: flex;
    align-items: center;
    position: relative;
}

.user-header .search-input {
    width: 100%;
    padding: 10px 10px 10px 10px;
    border-radius: 30px;
    border: 1px solid #ccc;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    background-color: #f8f8f8;
}

.user-header .search-dropdown-trigger {
    /* display: flex;
    align-items: center; */
    position: absolute;
    top: 45%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
}

.user-header .search-dropdown-menu {
    position: absolute;
    top: 100%;
    left: 150px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding: 10px;
    min-width: 150px;
    display: flex;
    flex-direction: column;
    margin-top: 5px;
}

.user-header .search-dropdown-item {
    display: flex;
    align-items: center;
    padding: 8px 10px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.user-header .search-dropdown-item:hover {
    background-color: #f5f5f5;
}

.user-header .search-dropdown-item .dropdown-icon {
    margin-right: 8px;
    font-size: 18px;
}