* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
}

.role-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.role-container .header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

.role-container .logo {
    font-size: 24px;
    font-weight: bold;
    color: #62495B;
}

.role-container .main-content {
    display: flex;
    flex-direction: initial;
    justify-content: space-between;
    width: 1120px;
    padding: 20px;
    margin-top: 50px;
    border-radius: 30px;
    border: 1px rgb(192, 192, 192) solid;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.role-container .question-section {
    width: 100%;
    /* max-width: 800px; */
    text-align: center;
    flex: 2;
    margin-right: 20px;
    margin-bottom: 180px;
}

.role-container .profile {
    display: table;
    margin-bottom: 20px;
}

.role-container .subtext {
    font-size: 14px;
    margin-bottom: 20px;
    color: #666;
}

.role-container .footer-note {
    font-size: 12px;
    color: #666;
    display: flex;
}

.role-container .box {
    height: 40px;
    width: 250px;
    border: 1px solid #ccc;
    align-items: center;
    margin-bottom: 5px;
    border-radius: 30px;
    padding: 6.5px;
}

.role-container .message{
    color:rgb(0, 141, 0) ;
    font-size: 14px;
}

.role-container .box:hover {
    background-color: #524f4f;
    color: #ebebeb;
}

.role-container .input {
    margin-bottom: 15px;
    text-align: left;
    width: 400px;
    border-radius: 10px;
}

.role-container .input input{
    width:400px;
    border-radius:10px; 
    border: 1px black solid;
    height: 45px;
    padding-left: 10px;
}

.role-container .input label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.role-container .navigation-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 70px;
}

.role-container .back-button {
    background-color: #ffffff;
    color: black;
    border: 1px black solid;
    padding: 10px 20px;
    border-radius: 30px;
    cursor: pointer;
}

.role-container .next-button {
    background-color: #62495B;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 30px;
    cursor: pointer;
}