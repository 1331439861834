* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
}

.skills-container {
    width: 1200px;
    margin: auto;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.skills-container .skills-content {
    display: flex;
    flex-direction: column;
    width: 1200px;
    min-height: 540px;
    padding: 20px 30px;
    margin-top: 30px;
    border-radius: 30px;
    border: 1px rgb(192, 192, 192) solid;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.skills-container .progress {
    font-size: 18px;
    background: none;
    height: fit-content;
}

.skills-container h2 {
    margin: 10px 0;
    font-size: 32px;
    font-weight: 700;
    width: 50%;
}

.skills-container h3 {
    font-size: 18px;
    font-weight: 600;
}

.skills-container .search-containers {
    margin-top: 20px;
    width: 50%;
}

.skills-container .input-label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.skills-container .input-label p {
    font-size: 14px;
    font-weight: 500;
}

.skills-container .search-skill-input {
    width: 100%;
    padding: 10px;
    /* margin: 20px 0; */
    border: 1px solid #ddd;
    border-radius: 20px;
}

.skills-container .selected-skillss {
    margin: 20px 0;
}

.skills-container .selected-skills-list {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.skills-container .selected-skill {
    background-color: #62495B;
    color: #fff;
    padding: 8px 12px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
}

.skills-container .selected-skill button {
    color: #fff;
    background: none;
    border: none;
    cursor: pointer;
}

.skills-container .popular-skills-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.skills-container .skill-button {
    padding: 6px 10px;
    background: none;
    /* background-color: #f0f0f0; */
    border: 1px solid #0f0f0f;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
}

.skills-container .navigation-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    width: 100%;
}

.skills-container .back-button {
    padding: 10px 20px;
    border: 1px solid #62495B;
    background: none;
    color: #62495B;
    border-radius: 25px;
    cursor: pointer;
}

.skills-container .next-button {
    padding: 10px 20px;
    background-color: #62495B;
    color: #fff;
    border: none;
    border-radius: 25px;
    cursor: pointer;
}