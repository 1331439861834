body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.client-survey1-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.client-survey1-container .header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

.client-survey1-container .logo img {
    height: 65px;
}

.client-survey1-container .survey1-contents {
    display: flex;
    justify-content: space-between;
    width: 1120px;
    height: 530px;
    padding: 20px;
    margin-top: 30px;
    border-radius: 30px;
    border: 1px rgb(192, 192, 192) solid;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.client-survey1-container .madatory {
    color: red;
    font-weight: 800;
    font-size: 18px;
    display: contents;
}

.client-survey1-container .survey1-section {
    flex: 3;
    margin-right: 20px;
}

.client-survey1-container .survey1-section h2 {
    font-size: 28px;
    font-weight: 600;
}

.client-survey1-container .survey1-section p {
    margin: 10px 0 20px 0;
    width: 60%;
}

.client-survey1-container .survey1-section textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 20px;
    resize: none;
}

.client-survey1-container .testimonial-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.client-survey1-container .testimonial {
    background-color: #f8f8f8;
    padding: 20px;
    border-radius: 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
}

.client-survey1-container .testimonial-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
    background-color: #ebebeb;
}

.client-survey1-container .testimonial-detail {
    margin-right: 52px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.client-survey1-container .profile-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
}

.client-survey1-container .testimonial-name {
    font-weight: bold;
}

.client-survey1-container .testimonial-stats span {
    margin-right: 10px;
    font-size: 12px;
    color: #999;
}

.client-survey1-container .testimonial-text {
    font-size: 15px;
    color: #333;
    text-align: justify;
}

.client-survey1-container .footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 60px;
    width: 100%;
}

.client-survey1-container .footer .back-btn,
.client-survey1-container .footer .next-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
}

.client-survey1-container .footer .back-btn {
    background-color: #ffffff;
    color: black;
    border: 1px black solid;
}

.client-survey1-container .footer .next-btn {
    background-color: #62495B;
    color: #fff;
}

.client-survey1-container .input-group input {
    width: 70%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    font-size: 1em;
    margin-bottom: 15px;
}

.client-survey1-container .input-group label {
    font-size: 16px;
    font-weight: 600;
}

.client-survey1-container input[type="number"] {
    appearance: textfield;
}

.client-survey1-container input::-webkit-outer-spin-button,
.client-survey1-container input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* .client-survey1-container .react-international-phone-input {
    width: 100% !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    height: 45px;
}

.client-survey1-container .react-international-phone-country-selector-button {
    height: 45px;
} */

.client-survey1-container .PhoneInputCountryIcon {
    margin-bottom: 12px;
}

.client-survey1-container .PhoneInputInput {
    margin-top: 12px;
}

.client-survey1-container .PhoneInputCountrySelectArrow {
    margin-bottom: 12px;
}