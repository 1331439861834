* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
  }

.role-edit-pop-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.role-main-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 550px;
    height: 300px;
    padding: 20px;
    background: white;
    border-radius: 30px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    position: relative;
}

.role-main-content .question-section {
    width: 100%;
    text-align: center;
    flex: 2;
    margin-bottom: 20px;
}

.role-main-content .input {
    margin-bottom: 15px;
    text-align: left;
    width: 100%;
}

.role-main-content input {
    width: 100%;
    border-radius: 10px;
    border: 1px solid black;
    height: 45px;
    padding: 10px;
}

.role-main-content .message {
    color: rgb(0, 141, 0);
    font-size: 14px;
}

.role-main-content .save-btn {
    background-color: #62495B;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 30px;
    cursor: pointer;
    align-self: flex-end;
}

.role-edit-pop-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 1.5em;
}