* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 1120px;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.logo {
  font-size: 24px;
  font-weight: bold;
  color: #62495B;
}

.userProfile {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.userName {
  font-weight: bold;
}

.userRole {
  font-size: 12px;
  color: #999;
}

.mainContent {
  display: flex;
  flex-direction: initial;
  justify-content: space-between;
  width: 1120px;
  padding: 20px;
  margin-top: 50px;
  border-radius: 30px;
  border: 1px rgb(192, 192, 192) solid;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.questionSection {
  width: 100%;
  /* max-width: 800px; */
  text-align: center;
  flex: 2;
  margin-right: 20px;
}

.profile {
  display: table;
  margin-bottom: 20px;
}

.subtext {
  font-size: 14px;
  margin-bottom: 20px;
  color: #666;
}

.footerNote {
  font-size: 12px;
  color: #666;
  text-align: justify;
  width: 80%;
}

.box {
  height: 40px;
  width: 250px;
  border: 1px solid #ccc;
  align-items: center;
  margin-bottom: 5px;
  border-radius: 30px;
  padding: 6.5px;
  cursor: pointer;
}

.box:hover {
  background-color: #524f4f;
  color: #ebebeb;
}

.navigationButtons {
  display: flex;
  justify-content: space-between;
  width: 1120px;
  margin-top: 80px;
}

.backButton {
  background-color: #ffffff;
  color: black;
  border: 1px black solid;
  padding: 10px 20px;
  border-radius: 30px;
}

.nextButton {
  background-color: #62495B;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 30px;
}

.sidebarSection {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.sidebar {
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 30px;
  /* border: 0.5px rgb(192, 191, 191) solid; */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 100%;
}

.sidebarHeader {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  background-color: #ebebeb;
  align-items: center;
}

.sidebarName {
  font-weight: bold;
}

.sidebarStats {
  margin-right: 100px;
}

.sidebarStats span {
  margin-right: 10px;
  font-size: 12px;
  color: #999;
}

.sidebarText {
  font-size: 14px;
  color: #333;
  text-align: justify;
}