.signup-form-container {
  width: 800px;
  margin: 0 auto;
  text-align: center;
  font-family: 'Lato', sans-serif;
  padding-top: 20px;
}

.signup-form-container .logo {
  font-size: 2em;
  margin-bottom: 20px;
}

.signup-form-container h2 {
  margin-bottom: 5px;
  font-weight: 500;
  color: gray;
}

.signup-form-container h3 {
  margin-bottom: 20px;
  font-weight: 600;
}

.signup-form-container form {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.signup-form-container .role-selection {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.signup-form-container .role-option {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #ddd;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  transition: border-color 0.3s;
  width: 360px;
  height: 90px;
  box-sizing: border-box;
  margin-top: 45px;
}

.signup-form-container .role-option.selected {
  background-color: grey;
  color: #ffffff;
}

.signup-form-container .role-option input {
  display: none;
}

.signup-form-container .role-content {
  display: flex;
  align-items: center;
}

.signup-form-container .icon svg {
  /* font-size: 2em; */
  margin-right: 25px;
  margin-bottom: 15px;
}

.signup-form-container .create-account-btn {
  width: 55%;
  padding: 10px;
  background: grey;
  color: #fff;
  border: none;
  border-radius: 30px;
  font-size: 1.2em;
  cursor: pointer;
  margin-top: 10px;
}

.signup-form-container .create-account-btn:hover {
  background-color: #62495B;
}

.signup-form-container .create-account-btn:disabled {
  background: #ddd;
  cursor: not-allowed;
}

.signup-form-container .create-account-btn.active {
  background-color: #62495B;
}

.signup-form-container .login-link {
  margin-top: 20px;
  font-weight: 600;
}

.signup-form-container .login-link a {
  color: #62495B;
}