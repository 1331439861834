* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
}

.job-skills-edit-popup-main {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.job-skills-edit-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    padding: 20px;
    width: 600px;
    border: 2px solid black;
}

.job-skills-edit-popup .skills-section {
    flex: 3;
    margin-right: 20px;
}

.job-skills-edit-popup .save-btn,
.job-skills-edit-popup .close-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    margin: 10px 0;
}

.job-skills-edit-popup .save-btn {
    background-color: #62495B;
    color: #fff;
    margin-left: 90%;
}

.job-skills-edit-popup .skills-edit-pop-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 1.5em;
}

/* --------------------- */

.job-skills-edit-popup .search-containers {
    margin-top: 20px;
    width: 90%;
}

.job-skills-edit-popup .input-label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.job-skills-edit-popup .input-label p {
    font-size: 14px;
    font-weight: 500;
}

.job-skills-edit-popup .search-skill-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 20px;
}

.job-skills-edit-popup .selected-skillss {
    margin: 20px 0;
}

.job-skills-edit-popup .selected-skills-list {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.job-skills-edit-popup .selected-skill {
    background-color: #62495B;
    color: #fff;
    padding: 8px 12px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
}

.job-skills-edit-popup .selected-skill button {
    color: #fff;
    background: none;
    border: none;
    cursor: pointer;
}

.job-skills-edit-popup .popular-skills-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.job-skills-edit-popup .skill-button {
    padding: 6px 10px;
    background: none;
    border: 1px solid #0f0f0f;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
}