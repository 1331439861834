* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
}

.ua-container {
    width: 100%;
}

.ua-container .logo img {
    height: 65px;
}

.ua-container .main-contents {
    width: 1120px;
    height: 450px;
    padding: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 30px;
    border: 1px rgb(192, 192, 192) solid;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.ua-container .content {
    margin-top: 30px;
    padding: 0px 20px;
}

/* .ua-container .footer {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 20px;
    width: 100%;
}

.ua-container .footer .back-btn,
.ua-container .footer .next-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
}

.ua-container .footer .back-btn {
    background-color: #ffffff;
    color: black;
    border: 1px black solid;
}

.ua-container .footer .next-btn {
    background-color: #62495B;
    color: #fff;
} */

/* ================= */

.ua-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-top: 20px;
}

.ua-container .header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.main-contents {
    flex: 1;
    padding: 20px;
    background-color: #ffffff;
}

.main-contents h1 {
    font-size: 24px;
    font-weight: 700;
    color: #333333;
}

.main-contents h2 {
    font-size: 20px;
    font-weight: 500;
    color: #333333;
}

.main-contents p {
    color: #666666;
}