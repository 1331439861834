body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.client-profile-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.client-profile-container .header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

.client-profile-container .logo img {
    height: 65px;
}

.client-profile-container .client-profile-contents {
    display: flex;
    flex-direction: column;
    width: 1120px;
    min-height: 480px;
    padding: 20px;
    margin-top: 30px;
    border-radius: 30px;
    gap: 20px;
    border: 1px rgb(192, 192, 192) solid;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.client-profile-container .client-card,
.client-profile-container .company-section,
.client-profile-container .contact-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.client-profile-container .main-heading {
    font-size: 24px;
    font-weight: 600;
}

.client-profile-container .user-detail {
    margin-bottom: 20px;
}

.client-profile-container .input-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 3px;
    font-size: 1em;
}

.client-profile-container .update-btn {
    padding: 10px 20px;
    background-color: #62495B;
    color: #fff;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    margin-top: 20px;
}

.client-profile-container .cancel-btn {
    padding: 10px 20px;
    background-color: #ffffff;
    color: #62495B;
    border: 1px solid #62495B;
    border-radius: 30px;
    cursor: pointer;
    margin-left: 15px;
}

.client-profile-container .client-info p {
    margin-bottom: 5px;
}

.client-profile-container .edit-btn-profile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.client-profile-container .heading {
    color: #A3A3A3;
    font-size: 16px;
    font-weight: 600;
}

.client-profile-container .content {
    color: #1B1919;
    font-size: 16px;
    font-weight: 500;
}

.client-profile-container .edit-button {
    background-color: white;
    color: #62495B;
    border: none;
    padding: 5px 10px;
    border-radius: 50%;
    border: 0.5px #62495B solid;
    cursor: pointer;
    height: fit-content !important;
}

.client-profile-container .edit-button:hover {
    background-color: black;
    color: #fff;
}

.client-profile-container .close-account {
    padding: 10px 20px;
    background: none;
    color: #62495B;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 15px;
}

.client-profile-container .footer {
    display: flex;
    justify-content: space-between;
    margin-top: 70px;
    width: 100%;
}

.client-profile-container .footer .back-btn,
.client-profile-container .footer .next-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
}

.client-profile-container .footer .back-btn {
    background-color: #ffffff;
    color: black;
    border: 1px black solid;
}

.client-profile-container .footer .next-btn {
    background-color: #62495B;
    color: #fff;
}