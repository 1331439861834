* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
}

.description-container {
    width: 1200px;
    margin: auto;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.description-container .description-content {
    display: flex;
    flex-direction: column;
    width: 1200px;
    min-height: 540px;
    padding: 20px 30px;
    margin-top: 30px;
    border-radius: 30px;
    border: 1px rgb(192, 192, 192) solid;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.description-container .progress {
    font-size: 18px;
    background: none;
    height: fit-content;
}

.description-container h2 {
    margin: 10px 0;
    font-size: 32px;
    font-weight: 700;
    width: 50%;
}

.description-container p {
    font-size: 16px;
    margin-bottom: 10px;
    color: #3F3F46;
}

.description-container ul {
    margin-bottom: 20px;
    padding-left: 20px;
    color: #3F3F46;
}

.description-container ul li {
    font-size: 16px;
    margin-bottom: 5px;
}

.description-container .description-input {
    margin-bottom: 20px;
}

.description-container .description-input label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.description-container .description-input textarea {
    width: 50%;
    height: 100px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #e0e0e0;
    border-radius: 15px;
}

.description-container .file-attachment {
    /* margin-bottom: 20px; */
    position: relative;
}

.description-container .file-attachment label {
    color: #62495B;
}

.description-container .attach-file-button {
    display: inline-block;
    padding: 10px 20px;
    background: none;
    border: 1px solid #62495B;
    border-radius: 30px;
    cursor: pointer;
}

.description-container .file-attachment input[type="file"] {
    display: none;
}

.description-container .file-attachment p {
    margin-top: 5px;
    color: #888;
    font-size: 12px;
}

.description-container .navigation-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    width: 100%;
}

.description-container .back-button {
    padding: 10px 20px;
    border: 1px solid #62495B;
    background: none;
    color: #62495B;
    border-radius: 25px;
    cursor: pointer;
}

.description-container .next-button {
    padding: 10px 20px;
    background-color: #62495B;
    color: #fff;
    border: none;
    border-radius: 25px;
    cursor: pointer;
}