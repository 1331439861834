* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
}

.language-card-main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.language-card {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background: white;
  padding: 20px;
  border-radius: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 1120px;
  height: auto;
  max-height: 90vh;
  overflow-y: auto;
}


.language-card h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: black;
}

.language-card .heading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.language-card .languages-list {
  text-align: left;
  margin-bottom: 20px;
}

.language-card input,
.language-card select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 45%;
  margin-right: 10px;
}

.language-card .language-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.language-card .language-item span {
  font-size: 16px;
}

.language-card .language-item select {
  margin-left: 10px;
}

.language-card .new-language button {
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  background-color: #62495B;
  color: #fff;
  margin-left: auto;
}

.language-card .save-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  background-color: #62495B;
  color: #fff;
  margin-top: 10px;
}

.language-card .close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background: none;
  color: black;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}