.password-form-container {
  width: 400px;
  margin: 0 auto;
  text-align: center;
  font-family: 'Lato', sans-serif;
  padding-top: 20px;
}

.password-form-container .logo {
  font-size: 2em;
  margin-bottom: 20px;
}

.password-form-container h2 {
  margin-bottom: 5px;
  text-align: center;
}

.password-form-container p {
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: center;
  font-weight: 600;
}

.password-form-container .content {
  background: #fff;
  width: 400px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: left;
  margin-top: 20%;
}

.password-form-container .input-group {
  margin-bottom: 15px;
  text-align: left;
  position: relative;
}

.password-form-container .input-group label {
  display: block;
}

.password-form-container .input-group input {
  width: 355px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  font-size: 1em;
  /* margin-left: 10px; */
}

.password-form-container .password-toggle {
  position: absolute;
  top: 48%;
  right: 20px;
  transform: translateY(-50%);
  cursor: pointer;
}

.password-form-container .checkbox {
  width: 334px;
  font-size: small;
  margin-left: 10px;
  display: flex;
  justify-content: space-between;
}

.password-form-container .login-btn {
  width: 355px;
  padding: 10px;
  background: #62495B;
  color: #fff;
  border: none;
  border-radius: 30px;
  font-size: 1.2em;
  cursor: pointer;
  margin-top: 60px;
}

.password-form-container .login-btn:hover {
  background: #62495B;
}

.password-form-container .footer {
  margin-top: 20px;
  font-size: 0.8em;
}

.password-form-container .footer a {
  color: #000;
}