* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
}

.profile-setup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.profile-setup-container .header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

.profile-setup-container .logo img {
    height: 65px;
    /* width: 150px; */
    cursor: pointer;
}

.profile-setup-container .content {
    width: 1120px;
    height: 450px;
    padding: 20px;
    margin-top: 50px;
    border-radius: 30px;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.profile-setup-container h1 {
    font-size: 24px;
    margin-bottom: 10px;
    margin-top: 1%;
}

.profile-setup-container p {
    margin-bottom: 20px;
    color: #666;
}

.profile-setup-container .form-container {
    display: flex;
}

.profile-setup-container .form-left {
    width: 80%;
}

.profile-setup-container .profile-form {
    width: auto;
}

.profile-setup-container .form-group {
    margin-bottom: 20px;
}

.profile-setup-container .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.profile-setup-container .input-group {
    display: flex;
    align-items: center;
}

.profile-setup-container .input-group .icon {
    margin-right: 10px;
}

.profile-setup-container input[type="text"],
.profile-setup-container input[type="tel"],
.profile-setup-container input[type="date"],
.profile-setup-container select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.profile-setup-container .phone-select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 10px;
}

.profile-setup-container .profile-pic-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profile-setup-container .profile-pic-placeholder {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: #fff;
    margin-bottom: 10px;
}

.profile-setup-container .profile-pic {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
}

.profile-setup-container .footer {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    width: 100%;
}

.profile-setup-container .footer .back-btn,
.profile-setup-container .footer .next-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
}

.profile-setup-container .footer .back-btn {
    background-color: #ffffff;
    color: black;
    border: 1px black solid;
}

.profile-setup-container .footer .next-btn {
    background-color: #62495B;
    color: #fff;
}