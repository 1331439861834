.user-register-form-container {
  width: 600px;
  margin: 0 auto;
  text-align: center !important;
  font-family: 'Lato', sans-serif;
  padding-top: 20px;
  margin-bottom: 30px;
}

.user-register-form-container .logo {
  font-size: 2em;
  margin-bottom: 20px;
}

.user-register-form-container h2 {
  margin: 0;
  margin-bottom: 20px;
}

.user-register-form-container form {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.user-register-form-container .madatory {
  color: red;
  font-weight: 800;
  font-size: 18px;
  display: contents;
}

.user-register-form-container .input-group {
  margin-bottom: 15px;
  text-align: left;
}

.user-register-form-container .input-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.user-register-form-container .input-group input,
.user-register-form-container .input-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 3px;
  font-size: 1em;
}

.user-register-form-container .password-group {
  position: relative;
}

.user-register-form-container .password-toggle {
  position: absolute;
  top: 70%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.user-register-form-container .checkbox-group {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
}

.user-register-form-container .checkbox-group input {
  margin-right: 10px;
  width: 20px;
}

.user-register-form-container .checkbox-group label {
  font-size: 15px;
  font-weight: 600;
}

.user-register-form-container .create-account-btn {
  width: 100%;
  padding: 10px;
  background: #62495B;
  color: #fff;
  border: none;
  border-radius: 30px;
  font-size: 1.2em;
  cursor: pointer;
  margin-top: 20px;
}

.user-register-form-container .create-account-btn:hover {
  background: #62495B;
}

.user-register-form-container .login-link {
  margin-top: 20px;
  font-weight: 600;
}

.user-register-form-container .social-login {
  display: contents;
  justify-content: center;
  margin-top: 20px;
}

.user-register-form-container .social-btn {
  border: none;
  border-radius: 30px;
  padding: 10px 20px;
  margin: 0 5px;
  font-size: 1em;
  cursor: pointer;
  width: 48%;
}

.user-register-form-container .google-btn {
  background: #4285F4;
  color: #fff;
  width: 100%;
}

.user-register-form-container .apple-btn {
  background: #fff;
  color: #000;
  border: 1px black solid;
}

.user-register-form-container .divider {
  margin: 20px 0;
  display: flex;
  align-items: center;
  text-align: center;
}

.user-register-form-container .divider::before,
.user-register-form-container .divider::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #ddd;
}

.user-register-form-container .divider:not(:empty)::before {
  margin-right: .25em;
}

.user-register-form-container .divider:not(:empty)::after {
  margin-left: .25em;
}

.user-register-form-container .Toastify__toast-container {
  text-align: start;
}