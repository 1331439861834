.user-nav-container {
    position: relative;
    display: inline-block;
    font-family: 'Lato', sans-serif;
}

.user-nav-container .user-profile {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 0.5px black solid;
    border-radius: 30px;
    height: 50px;
    width: 160px;
}

.user-nav-container .user-name {
    font-weight: bold;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
}

.user-nav-container .user-role {
    font-size: 12px;
    color: #999;
}

.user-nav-container .dropdown-nav-buttons {
    position: absolute;
    width: max-content;
    border-radius: 20px;
    overflow: hidden;
    top: 78%;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.user-nav-container .dropdown-nav-item {
    padding: 10px 20px;
    cursor: pointer;
}

.user-nav-container .dropdown-nav-item:hover {
    background-color: #f5f5f5;
}