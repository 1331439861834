.membership-container {
    width: 1120px;
    margin: 0 auto;
    font-family: 'Lato', sans-serif;
}

.membership-container .membership-main {
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    margin-bottom: 10%;
}

.membership-container .membership-main h1 {
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: 700;
}

.membership-container .links {
    display: flex;
    /* justify-content: space-between; */
    margin-bottom: 20px;
    gap: 40px;
}

.membership-container .links a {
    font-size: 16px;
    font-weight: 600;
    color: #62495B;
    text-decoration: underline;
}

.membership-container .form-group {
    margin-bottom: 20px;
    margin-top: 30px;
}

.membership-container .form-group label {
    font-size: 16px;
    margin-bottom: 5px;
    display: block;
    font-weight: 600;
}

.membership-container .form-group input {
    width: 40%;
    padding: 10px;
    border-radius: 15px;
    border: 1px solid #ccc;
}

.membership-container .plans {
    display: flex;
    gap: 20px;
}

.membership-container .plan {
    flex: 1;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.membership-container .plan-header {
    display: flex;
    justify-content: space-between;
}

.membership-container .basic {
    border: 1px solid #E5E5E5;
}

.membership-container .plus {
    background-color: #62495B;
    color: #fff;
}

.membership-container .plan h2 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
}

.membership-container .plan .price {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 10px;
}

.membership-container .plan .description {
    font-size: 14px;
    margin-bottom: 10px;
}

.membership-container .plan .benefits {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 20px;
}

.membership-container .plan .benefits li {
    font-size: 14px;
    margin-bottom: 10px;
}

.membership-container .basic .note {
    font-size: 12px;
    color: #000;
    margin-bottom: 20px;
}

.membership-container .plus .note {
    font-size: 12px;
    color: #fff;
    margin-bottom: 20px;
}

.membership-container .continue-button {
    padding: 10px 20px;
    border-radius: 30px;
    border: none;
    cursor: pointer;
    font-size: 16px;
}

.membership-container .basic .continue-button {
    color: #62495B;
    border: 1px solid #62495B;
    background: none;
}

.membership-container .plus .continue-button {
    background-color: #fff;
    color: #62495B;
}