body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.thank-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.thank-container .header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

.thank-container .logo img {
    height: 65px;
}

.thank-container .profile-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 500px;
    /* height: 200px; */
    /* border-radius: 50%; */
    overflow: hidden;
    margin-bottom: 20px;
}

.thank-container .profile-circle img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.thank-container .profile-circle h1{
    color: #62495B;
}

.thank-container .thank-contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1120px;
    min-height: 480px;
    padding: 20px;
    margin-top: 30px;
    border-radius: 30px;
    /* border: 1px rgb(192, 192, 192) solid; */
    /* box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); */
}

.thank-container .thank-contents a {
    color: #62495B;
}

.thank-container h1 {
    text-align: center;
    font-weight: 700;
    /* margin-top: 70px; */
}

.thank-container h3 {
    text-align: justify;
    margin-top: 30px;
    padding: 0px 50px;
    font-size: 24px;
}

.thank-container .footer {
    display: flex;
    justify-content: space-between;
    margin-top: 70px;
    width: 100%;
}

.thank-container .footer .back-btn,
.thank-container .footer .next-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
}

.thank-container .footer .back-btn {
    background-color: #ffffff;
    color: black;
    border: 1px black solid;
}

.thank-container .footer .next-btn {
    background-color: #62495B;
    color: #fff;
}