* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
}

.job-scope-edit-popup-main {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.job-scope-edit-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    padding: 20px;
    width: 600px;
    border: 2px solid black;
}

.job-scope-edit-popup .scope-section {
    flex: 3;
    margin-right: 20px;
}

.job-scope-edit-popup .save-btn,
.job-scope-edit-popup .close-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    margin: 10px 0;
}

.job-scope-edit-popup .save-btn {
    background-color: #62495B;
    color: #fff;
    margin-left: 90%;
}

.job-scope-edit-popup .scope-edit-pop-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 1.5em;
}

/* ---------------------- */

.job-scope-edit-popup label {
    display: block;
    font-size: 16px;
    font-weight: 600;
}

.job-scope-edit-popup input[type="radio"] {
    width: 25px;
    accent-color: #62495B;
    cursor: pointer;
}

.job-scope-edit-popup input[type="radio"]:checked {
    background-color: #62495B;
    border-color: #62495B;
}

.job-scope-edit-popup .label-detail {
    font-size: 14px;
    font-weight: 400;
    color: #A3A3A3;
    padding-left: 24px;
}

.job-scope-edit-popup .selected-size {
    font-size: 16px;
    font-weight: 600;
}

.job-scope-edit-popup .selected-detail {
    font-size: 14px;
    font-weight: 400;
    color: #A3A3A3;
}

.job-scope-edit-popup .edit-btn-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
    width: 90%;
}

.job-scope-edit-popup .edit-button {
    background-color: white;
    color: #62495B;
    border: none;
    padding: 5px 10px;
    border-radius: 50%;
    border: 0.5px #62495B solid;
    cursor: pointer;
    margin-top: 10px;
    height: fit-content;
}

.job-scope-edit-popup .project-time p {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 3px;
}

.job-scope-edit-popup .project-time-options {
    display: flex;
    gap: 20px;
    color: #A3A3A3;
    font-size: 16px;
    font-weight: 400;
}

.job-scope-edit-popup .selected-time {
    font-size: 16px;
    font-weight: 600;
}

.job-scope-edit-popup .project-experience {
    margin-top: 10px;
}

.job-scope-edit-popup .project-experience h6 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0px;
}

.job-scope-edit-popup .project-experience p {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
}

.job-scope-edit-popup .project-experience span {
    font-size: 14px;
    font-weight: 400;
    color: #A3A3A3;
}

.job-scope-edit-popup .project-exp-options {
    margin-top: 5px;
}

.job-scope-edit-popup .selected-exp {
    font-size: 16px;
    font-weight: 600;
}