body {
    margin: 0;
    padding: 0;
    font-family: 'Lato', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.saved-jobs-container {
    width: 1150px;
}

.saved-jobs-container .saved-jobs {
    width: 100%;
}

.saved-jobs-container .back-btn {
    border: none;
    background: none;
    margin: 20px 0px;
    color: #62495B;
}

.saved-jobs-container .saved-job-item {
    padding: 20px;
    background-color: #fff;
    border: 1px solid #828282;
    margin-bottom: 20px;
    border-radius: 30px;
}

.saved-jobs-container .saved-job-heading {
    background-color: #f8f8f8;
    border-radius: 10px;
    height: 80px;
    display: flex;
    justify-content: space-between;
    padding: 0px 20px;
}

.saved-jobs-container .saved-job-item button {
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
}

.saved-jobs-container .saved-job-category {
    margin-top: 10px;
}

.saved-jobs-container .saved-job-item h4 {
    margin: 0;
    font-weight: 600;
    margin-bottom: 2%;
    padding-top: 1%;
}

.saved-jobs-container .saved-job-item p {
    margin: 10px 0;
}

.saved-jobs-container .saved-job-tags {
    margin: 10px 0;
}

.saved-jobs-container .saved-job-tag {
    display: inline-block;
    background-color: #E3C2FE;
    padding: 5px 10px;
    margin-right: 5px;
    border-radius: 30px;
}

.saved-jobs-container .saved-job-details {
    display: flex;
    justify-content: space-between;
    width: 65%;
    margin-bottom: 1%;
}

.saved-jobs-container .saved-job-rating {
    display: flex;
    justify-content: space-between;
}