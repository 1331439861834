body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.client-survey6-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.client-survey6-container .header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

.client-survey6-container .logo img {
    height: 65px;
}

.client-survey6-container .survey6-contents {
    display: flex;
    justify-content: space-between;
    width: 1120px;
    min-height: 480px;
    padding: 20px;
    margin-top: 30px;
    border-radius: 30px;
    border: 1px rgb(192, 192, 192) solid;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.client-survey6-container .madatory {
    color: red;
    font-weight: 800;
    font-size: 18px;
    display: contents;
}

.client-survey6-container .survey6-section {
    flex: 3;
    margin-right: 20px;
}

.client-survey6-container .survey6-section h2 {
    font-size: 28px;
    font-weight: 600;
}

.client-survey6-container .survey6-section p {
    margin: 10px 0 20px 0;
    width: 90%;
}

.client-survey6-container .survey6-section textarea {
    width: 80%;
    height: 100px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 20px;
    resize: none;
}

.client-survey6-container .testimonial-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.client-survey6-container .testimonial {
    background-color: #f8f8f8;
    padding: 20px;
    border-radius: 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
}

.client-survey6-container .testimonial-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
    background-color: #ebebeb;
}

.client-survey6-container .testimonial-detail {
    margin-right: 52px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.client-survey6-container .profile-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
}

.client-survey6-container .testimonial-name {
    font-weight: bold;
    text-align: center;
}

.client-survey6-container .testimonial-detail span {
    font-size: 14px;
}

.client-survey6-container .testimonial-text {
    font-size: 15px;
    color: #333;
    text-align: justify;
}

.client-survey6-container .footer {
    display: flex;
    justify-content: space-between;
    margin-top: 70px;
    width: 100%;
}

.client-survey6-container .footer .back-btn,
.client-survey6-container .footer .next-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
}

.client-survey6-container .footer .back-btn {
    background-color: #ffffff;
    color: black;
    border: 1px black solid;
}

.client-survey6-container .footer .next-btn {
    background-color: #62495B;
    color: #fff;
}

.client-survey6-container .input-group input {
    width: 70%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    font-size: 1em;
    margin-bottom: 15px;
}

.client-survey6-container form {
    margin-top: 20px;
}

.client-survey6-container .question {
    margin-bottom: 20px;
}

.client-survey6-container .question label {
    display: block;
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 600;
}

.client-survey6-container .options {
    display: flex;
    gap: 20px;
}

.client-survey6-container .options input[type="radio"] {
    width: 25px;
    accent-color: #62495B;
    /* margin-right: 10px; */
    cursor: pointer;
}

.client-survey6-container input[type="radio"]:hover {
    transform: scale(1.1);
}

.client-survey6-container input[type="radio"]:checked {
    background-color: #62495B;
    border-color: #62495B;
}

.client-survey6-container .options label {
    font-size: 16px;
    font-weight: 500;
}