* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
}

.project-preview-container {
    width: 1200px;
    margin: auto;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.project-preview-content {
    display: flex;
    flex-direction: column;
    width: 1200px;
    min-height: 540px;
    padding: 20px 30px;
    margin-top: 30px;
    border-radius: 30px;
    border: 1px rgb(192, 192, 192) solid;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.edit-btn-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
}

.edit-button {
    background-color: white;
    color: #62495B;
    border: none;
    padding: 5px 10px;
    border-radius: 50%;
    border: 0.5px #62495B solid;
    cursor: pointer;
    margin-top: 10px;
    height: fit-content;
}

.project-header h2 {
    font-size: 24px;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 20px;
}

.project-header p {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 20px;
    color: #3F3F46;
    width: 80%;
}

.project-details {
    margin-bottom: 30px;
}

.project-detail {
    margin-bottom: 15px;
}

.project-detail h4 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
}

.project-detail p {
    font-size: 14px;
    font-weight: 400;
    color: #3F3F46;
}

.project-questions {
    margin-bottom: 30px;
}

.project-questions h3 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
}

.project-questions p {
    font-size: 14px;
    font-weight: 400;
    color: #A3A3A3;
    margin-bottom: 10px;
}

.add-question {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
    width: 20%;
}

.add-question h4 {
    font-size: 16px;
    font-weight: 600;
}

.add-question input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.add-question button {
    padding: 10px 10px;
    background: none;
    color: #62495B;
    border: 1px solid #62495B;
    border-radius: 30px;
    cursor: pointer;
}

.suggested-questions {
    margin-bottom: 20px;
}

.suggested-questions h5 {
    font-size: 16px;
    font-weight: 400;
    color: #1B1919;
}

input[type='checkbox'] {
    accent-color: #62495B;
}

.question-option {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.question-option label {
    font-size: 16px;
    font-weight: 400;
    margin-left: 10px;
    color: #000;
}

.navigation-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    width: 100%;
    font-size: 20px;
    font-weight: 600;
}

.back-button {
    padding: 10px 20px;
    border: 1px solid #62495B;
    background: none;
    color: #62495B;
    border-radius: 25px;
    cursor: pointer;
}

.post-button {
    padding: 10px 20px;
    background-color: #62495B;
    color: #fff;
    border: none;
    border-radius: 25px;
    cursor: pointer;
}

.save-button {
    padding: 10px 20px;
    color: #62495B;
    border: none;
    background: none;
    cursor: pointer;
}