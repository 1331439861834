body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.client-preview-container {
    width: 1200px;
}

.client-preview-container .main-heading {
    font-size: 32px;
    font-weight: 700;
    display: flex;
    width: 100%;
    margin-bottom: 20px;
}

.client-preview-container .client-preview-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

.client-preview-container .edit-btn-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
}

.client-preview-container .edit-btn-profile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.client-preview-container .client-preview-logo {
    height: 65px;
}

.client-preview-container .client-preview-main {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 20px;
}

.client-preview-container .notice {
    width: 100%;
    padding: 20px;
    background-color: #e6f5e6;
    border: 1px solid #a0cfa0;
    color: #2c6a2c;
    border-radius: 10px;
    margin-bottom: 20px;
    text-align: justify;
}

.client-preview-container .notice a {
    color: #4d3a47;
}

.client-preview-container .client-preview-banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 10px;
    margin-bottom: 20px;
}

.client-preview-container .banner-content {
    flex: 1;
}

.client-preview-container .submit-button:hover {
    background-color: #62495B;
}

.client-preview-container .client-details {
    display: flex;
    width: 740px;
    flex-direction: column;
    gap: 20px;
}

.client-preview-container .supporting-doc {
    width: 400px;
    margin-left: 5%;
}

.client-preview-container .client-card,
.client-preview-container .company-section,
.client-preview-container .customization-section,
.client-preview-container .systems-section,
.client-preview-container .integration-section,
.client-preview-container .maintenance-section,
.client-preview-container .budget-section {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.client-preview-container .doc-section {
    background-color: #fff;
    padding: 20px;
}

.client-preview-container .doc-section .doc_filename {
    /* background-color: #62495B; */
    color: rgb(0, 0, 0);
    padding: 10px 20px;
    border-radius: 30px;
    height: 40px;
    min-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
    margin-right: 10px;
}

.client-preview-container .selected-files {
    display: flex;
    align-items: center;
    padding: 0px 10px;
    border-radius: 10px;
    border: 1px solid black;
    background-color: #C0C0C0;
    color: #fff;
    width: fit-content;
    margin-bottom: 5px;
}

.client-preview-container .selected-files .remove-button {
    height: 20px;
    width: 20px;
    padding: initial;
    border: none;
    border-radius: 50%;
    background-color: #fff;
    color: #000;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.client-preview-container .heading {
    color: #A3A3A3;
    font-size: 16px;
    font-weight: 600;
}

.client-preview-container .content {
    color: #1B1919;
    font-size: 16px;
    font-weight: 500;
}

.client-preview-container .box-heading {
    font-weight: 700;
    font-size: 24px;
    color: #1B1919;
}

.client-preview-container .client-info h3 {
    margin-bottom: 10px;
}

.client-preview-container .client-info p {
    margin-bottom: 5px;
}

.client-preview-container .edit-button {
    background-color: white;
    color: #62495B;
    border: none;
    padding: 5px 10px;
    border-radius: 50%;
    border: 0.5px #62495B solid;
    cursor: pointer;
    margin-top: 10px;
}

.client-preview-container .edit-button:hover {
    background-color: black;
    color: #fff;
}

.client-preview-container .client-preview-footer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 20px;
    border-top: 1px solid #ccc;
    margin-top: 20px;
}

.client-preview-container .submit-button,
.client-preview-container .back-button {
    background-color: #62495B;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 30px;
    cursor: pointer;
    margin-top: 10px;

}

.client-preview-container .foot {
    font-size: 20px;
    font-weight: 600;
    height: 56px;
    width: 268px;
}