* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
}

.profile-ready-container {
    text-align: center;
    padding: 20px;
    width: 1120px;
}

.profile-ready-container header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}

.profile-ready-container .logo img {
    height: 65px;
    /* width: 150px; */
    cursor: pointer;
}

.profile-ready-container p{
    width: 60%;
}

.profile-ready-container .user-info {
    display: flex;
    align-items: center;
}

.profile-ready-container .user-avatar {
    width: 40px;
    height: 40px;
    background-color: #ccc;
    border-radius: 50%;
    margin-right: 10px;
}

.profile-ready-container main {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
}

.profile-ready-container .profile-circle {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 20px;
}

.profile-ready-container .profile-circle img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.profile-ready-container .button-group {
    margin-top: 20px;
}

.profile-ready-container .button-group button {
    padding: 10px 20px;
    margin: 0 10px;
    cursor: pointer;
    border-radius: 30px;
}

.profile-ready-container .view-profile-btn{
    background-color: white;
    color: black;
    border: 1px black solid;
}

.profile-ready-container .browse-job-btn{
    border: none;
    background-color: #62495B;
    color: white;
}

