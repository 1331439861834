* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
}

.scope-container {
    width: 1200px;
    margin: auto;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.scope-container .scope-content {
    display: flex;
    flex-direction: column;
    width: 1200px;
    min-height: 540px;
    padding: 20px 30px;
    margin-top: 30px;
    border-radius: 30px;
    border: 1px rgb(192, 192, 192) solid;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.scope-container .progress {
    font-size: 18px;
    background: none;
    height: fit-content;
}

.scope-container h2 {
    margin: 10px 0;
    font-size: 32px;
    font-weight: 700;
    width: 50%;
}

.scope-container .description {
    font-size: 15px;
    font-weight: 500;
    width: 50%;
}

.scope-container label {
    display: block;
    font-size: 16px;
    font-weight: 600;
}

.scope-container input[type="radio"] {
    width: 25px;
    accent-color: #62495B;
    cursor: pointer;
}

.scope-container input[type="radio"]:checked {
    background-color: #62495B;
    border-color: #62495B;
}

.scope-container .label-detail {
    font-size: 14px;
    font-weight: 400;
    color: #A3A3A3;
    padding-left: 24px;
}

.scope-container .selected-size {
    font-size: 16px;
    font-weight: 600;
}

.scope-container .selected-detail {
    font-size: 14px;
    font-weight: 400;
    color: #A3A3A3;
}

.scope-container .edit-btn-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
    width: 45%;
}

.scope-container .edit-button {
    background-color: white;
    color: #62495B;
    border: none;
    padding: 5px 10px;
    border-radius: 50%;
    border: 0.5px #62495B solid;
    cursor: pointer;
    margin-top: 10px;
    height: fit-content;
}

.scope-container .project-time p {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 3px;
}

.scope-container .project-time-options {
    display: flex;
    gap: 20px;
    color: #A3A3A3;
    font-size: 16px;
    font-weight: 400;
}

.scope-container .selected-time {
    font-size: 16px;
    font-weight: 600;
}

.scope-container .project-experience {
    margin-top: 10px;
}

.scope-container .project-experience h6 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0px;
}

.scope-container .project-experience p {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
}

.scope-container .project-experience span {
    font-size: 14px;
    font-weight: 400;
    color: #A3A3A3;
}

.scope-container .project-exp-options {
    margin-top: 5px;
}

.scope-container .selected-exp {
    font-size: 16px;
    font-weight: 600;
}

.scope-container .navigation-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    width: 100%;
}

.scope-container .back-button {
    padding: 10px 20px;
    border: 1px solid #62495B;
    background: none;
    color: #62495B;
    border-radius: 25px;
    cursor: pointer;
}

.scope-container .next-button {
    padding: 10px 20px;
    background-color: #62495B;
    color: #fff;
    border: none;
    border-radius: 25px;
    cursor: pointer;
}