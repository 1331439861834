.login-form-container {
    width: 400px;
    margin: 0 auto;
    text-align: center;
    font-family: 'Lato', sans-serif;
    padding-top: 20px;
  }
  
  .login-form-container .logo {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .login-form-container h2 {
    margin-bottom: 30px;
    margin-right: auto;
    text-align: center;
  }
  
  .login-form-container p {
    margin-bottom: 20px;
    text-align: center;
    font-weight: 600;
  }

  .login-form-container .content{
    background: #fff;
    width: 400px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: left;
    margin-top: 20%;
  }
  
  .login-form-container .input-group {
    margin-bottom: 15px;
    text-align: left;
  }
  
  .login-form-container .input-group label {
    display: block;
  }
  
  .login-form-container .input-group input {
    width: 355px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    font-size: 1em;
    /* margin-left: 10px; */
  }
  
  .login-form-container .continue-btn {
    width: 355px;
    padding: 10px;
    background: #62495B;
    color: #fff;
    border: none;
    border-radius: 30px;
    font-size: 1.2em;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .login-form-container .continue-btn:hover {
    background: #62495B;
  }
  
  .login-form-container .divider {
    margin: 20px 0;
    display: flex;
    align-items: center;
    text-align: center;
  }
  
  .login-form-container .divider::before,
  .login-form-container .divider::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #ddd;
  }
  
  .login-form-container .divider:not(:empty)::before {
    margin-right: .25em;
  }
  
  .login-form-container .divider:not(:empty)::after {
    margin-left: .25em;
  }
  
  .login-form-container .social-login {
    display:flex;
    flex-direction: column;
    gap: 10px;
    margin-right: 10px;
  }
  
  .login-form-container .social-btn {
    border: none;
    border-radius: 30px;
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
  }
  
  .login-form-container .google-btn {
    background: #4285F4;
    color: #fff;
    width: 355px;
  }
  
  .login-form-container .apple-btn {
    background: #fff;
    color: #000;
    border: 1px black solid;
  }
  
  .login-form-container .signup-link {
    margin-top: 20px;
    text-align: center;
  }
  
  .login-form-container .footer {
    margin-top: 20px;
    font-size: 0.8em;
  }
  
  .login-form-container .footer a {
    color: #000;
  }
  