* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
}

.language-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.language-container .header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.language-container .heading {
  font-weight: bold;
  display: flex;
}

.language-container .logo img {
  height: 65px;
  /* width: 150px; */
}

.language-container .card {
  background: white;
  padding: 20px;
  border-radius: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 1120px;
  height: 450px;
  margin-top: 50px;
  box-sizing: border-box;
  overflow-y: auto;
}

.language-container .card h2 {
  font-size: 24px;
  margin-bottom: 20px;
  margin-right: auto;
  color: black;
}

.language-container .card p {
  color: #555;
  font-size: 14px;
  margin-bottom: 20px;
}

.language-container .languages-list {
  text-align: left;
  margin-bottom: 20px;
}

.language-container input {
  margin-top: 5px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 31%;
}

.language-container .language-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.language-container .language-item span {
  font-size: 16px;
}

.language-container .language-item select {
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ddd;
  width: 18%;
  height: 46px;
  margin-right: 50%;
  margin-left: 25px;
}

.language-container .add-language {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.language-container .add-language input {
  width: 25%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin-right: 10px;
}

.language-container .add-language select {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin-right: 31%;
  width: 33%;
}

.language-container .new-language button {
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  background-color: #62495B;
  color: #fff;
  margin-left: auto;
}

.language-container .footer {
  display: flex;
  justify-content: space-between;
  margin-top: 80px;
  width: 100%;
}

.language-container .footer .back-btn,
.language-container .footer .next-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
}

.language-container .footer .back-btn {
  background-color: #ffffff;
  color: black;
  border: 1px black solid;
}

.language-container .footer .next-btn {
  background-color: #62495B;
  color: #fff;
}