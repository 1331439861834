* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
}

.experience-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.experience-main-container .header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

.experience-main-container .logo {
    font-size: 24px;
    font-weight: bold;
    color: #62495B;
    cursor: pointer;
}

.experience-main-container .main-content {
    display: flex;
    flex-direction: column;
    width: 1120px;
    height: 450px;
    overflow-y: auto;
    padding: 20px;
    margin-top: 50px;
    border-radius: 30px;
    border: 1px solid rgb(192, 192, 192);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.experience-main-container .experience-container {
    width: 100%;
    margin: 0 auto;
    text-align: left;
}

.experience-main-container h2 {
    font-size: 24px;
    margin-right: 22%;
    margin-top: 2%;
}

.experience-main-container p {
    font-size: 14px;
    color: #555;
}

.experience-main-container .experience-card {
    border: 1px solid #ddd;
    width: 40%;
    padding: 20px;
    margin: 20px 0;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.experience-main-container .experience-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.experience-main-container .experience-card-header h3 {
    margin: 0;
    font-size: 18px;
}

.experience-main-container .experience-card-icons {
    font-size: 18px;
    display: flex;
    gap: 10px;
}

.experience-main-container .experience-card-icons .edit-icon,
.experience-main-container .experience-card-icons .delete-icon {
    cursor: pointer;
}

.experience-main-container .experience-card-icons .edit-icon:hover,
.experience-main-container .experience-card-icons .delete-icon:hover {
    color: #62495B;
}

.experience-main-container .experience-description {
    color: #777;
    margin-top: 10px;
}

.experience-main-container .add-experience-btn {
    background-color: #62495B;
    color: #fff;
    border: none;
    border-radius: 50%;
    font-size: 24px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: initial;
}

.experience-main-container .add-experience-btn:hover {
    background-color: #62495B;
}

.experience-main-container .navigation-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 80px;
}

.experience-main-container .back-button {
    background-color: #ffffff;
    color: black;
    border: 1px black solid;
    padding: 10px 20px;
    border-radius: 30px;
    cursor: pointer;
}

.experience-main-container .next-button {
    background-color: #62495B;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 30px;
    cursor: pointer;
}
