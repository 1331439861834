body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container {
  text-align: center;
  width: 100%;
  height: 700px;
  margin-top: 20px;
}

.header {
  margin-bottom: 20px;
  margin-block: auto;
}

.logo {
  margin-inline: auto;
}

.card {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 6%;
  width: 520px;
  height: 490px;
}

.card h2 {
  font-size: 24px;
  font-weight: 700;
}

.cardTitle {
  margin: 0 0 20px;
  color: black;
}

.imageContainer {
  margin-bottom: 8%;
  margin-top: 8%;
}

.imageContainer img {
  height: 215px;
  width: 241px;
}

.cardText {
  color: #555;
  font-size: 14px;
  margin-bottom: 20px;
  font-weight: 600;
}

.buttons {
  /* height: 40px; */
  display: flex;
  justify-content: center;
}

.loginButton {
  padding: 10px 20px;
  height: 45px;
  width: 100px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  background-color: #62495B;
  color: #fff;
  margin-block: auto;
}

.loginButton:hover {
  background-color: #62495B;
}

.footer {
  margin-top: 20px;
}

.footerText {
  font-size: 12px;
  color: #888;
}

.footerLink {
  color: #62495B;
  text-decoration: none;
}