body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container {
  text-align: center;
  width: 100%;
  height: 700px;
}

.header {
  margin-bottom: 20px;
  margin-block: auto;
}

.logo {
  margin-inline: auto;
}

.card {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 6%;
  width: 600px;
  height: 570px;
}

.card h2 {
  font-size: 24px;
  font-weight: 700;
}

.card p {
  font-weight: 600;
}

.imageContainer {
  height: auto;
  margin-bottom: 8%;
  margin-top: 8%;
}

.imageContainer img {
  height: 215px;
  width: 241px;
}

.cardText {
  color: #555;
  font-size: 14px;
  margin-bottom: 20px;
}

.form {
  text-align: left;
}

.formLabel {
  display: block;
  margin-bottom: 5px;
  color: #333;
  font-weight: 600;
}

.formInput {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.buttons {
  height: 40px;
  display: flex;
  justify-content: space-between;
}

.cancelButton,
.submitButton {
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
}

.cancelButton {
  background-color: #e0e0e0;
  color: #333;
  height: 50px;
}

.submitButton {
  background-color: #62495B;
  color: #fff;
  width: 125px;
  margin-block: auto;
}

.submitButton:hover {
  background-color: #62495B;
}

.footer {
  margin-top: 20px;
}

.footerText {
  font-size: 12px;
  color: #888;
}

.footerLink {
  color: #62495B;
  text-decoration: none;
}

.successMessage {
  color: green;
  font-weight: bold;
  margin-bottom: 10px;
}

.errorMessage {
  color: red;
  font-weight: bold;
  margin-bottom: 10px;
}