.profile-preview-container {
    display: flex;
    flex-direction: column;
    width: 1200px;
    align-items: center;
    padding: 20px;
    font-family: 'Lato', sans-serif;
    /* font-family: Arial, sans-serif; */
}

.profile-preview-container .profile-preview-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

.profile-preview-container .edit-btn-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
}

.profile-preview-container .edit-btn-profile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.profile-preview-container .profile-preview-logo {
    height: 65px;
    /* width: 150px; */
}

.profile-preview-container .profile-preview-main {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 20px;
}

.profile-preview-container .profile-preview-banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 10px;
    margin-bottom: 20px;
}

.profile-preview-container .banner-content {
    flex: 1;
}

.profile-preview-container .submit-button:hover {
    background-color: #62495B;
}

.profile-preview-container .profile-details {
    display: flex;
    width: 700px;
    flex-direction: column;
    gap: 20px;
}

.profile-preview-container .profile-card,
.profile-preview-container .skills-section,
.profile-preview-container .work-history-section,
.profile-preview-container .education-section {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.profile-preview-container .languages-section {
    background-color: #fff;
    padding: 20px;
}

.profile-preview-container .profile-pic {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 20px;
}

.profile-preview-container .profile-info h3 {
    margin-bottom: 10px;
}

.profile-preview-container .profile-info p {
    margin-bottom: 5px;
}

.profile-preview-container .edit-button {
    background-color: white;
    color: #62495B;
    border: none;
    padding: 5px 10px;
    border-radius: 50%;
    border: 0.5px #62495B solid;
    cursor: pointer;
    margin-top: 10px;
}

.profile-preview-container .edit-button:hover {
    background-color: black;
    color: #fff;
}

.profile-preview-container .skills-section ul,
.profile-preview-container .languages-section ul {
    list-style-type: none;
    padding: 0;
}

.profile-preview-container .skills-section li {
    background-color: black;
    color: #fff;
    padding: 5px 10px;
    border-radius: 30px;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
}

.profile-preview-container .profile-preview-footer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 20px;
    border-top: 1px solid #ccc;
    margin-top: 20px;
}

.profile-preview-container .submit-button,
.profile-preview-container .back-button {
    background-color: #62495B;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 30px;
    cursor: pointer;
    margin-top: 10px;
}