body {
    font-family: 'Lato', sans-serif;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.setting-container .setting-main {
    display: flex;
    width: 1200px;
}

.setting-container .setting-sidebar {
    width: 300px;
    background-color: #fff;
    padding: 20px;
    border-right: 1px solid #eee;
}

.setting-container .setting-sidebar h2 {
    margin-top: 0;
    margin-bottom: 25px;
    font-weight: 700;
}

.setting-container .setting-sidebar .menu-heading {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
}

.setting-container .setting-sidebar ul {
    list-style-type: none;
    padding: 0;
}

.setting-container .setting-sidebar ul ul {
    padding-left: 20px;
}

.setting-container .setting-sidebar .menu-options {
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    color: #A3A3A3;
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 20px;
}

.setting-container .setting-sidebar .menu-options:hover,
.setting-container .setting-sidebar .menu-options.active {
    background-color: #62495B;
    color: #fff;
    border-radius: 15px;
}


.setting-container .setting-content {
    flex: 1;
    padding: 20px;
}

.setting-container .setting-content h2 {
    margin-top: 0;
    font-weight: 700;
}

.setting-container .contact-section {
    display: flex;
    flex-direction: column;
}

.setting-container .contact-card {
    background-color: #fff;
    padding: 20px;
    margin-bottom: 20px;
    border: 1px solid #eee;
    border-radius: 20px;
}

.setting-container .contact-card h3 {
    margin-top: 0;
    font-weight: 700;
}

.setting-container .contact-card p {
    margin: 5px 0;
}

.setting-container .contact-card .head {
    color: black;
    font-weight: 600;
    font-size: 16px;
}

.setting-container .contact-card .record {
    color: gray;
    font-weight: 600;
    font-size: 16px;
}

.setting-container .contact-card .client-account {
    margin-top: 20px;
    margin-bottom: 10px;
}

.setting-container .contact-card .agency-account {
    margin-top: 20px;
    margin-bottom: 10px;
}

.setting-container .contact-card .user-detail {
    margin-bottom: 20px;
}

.setting-container .contact-card .close-account {
    padding: 10px 20px;
    background: none;
    color: #62495B;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 15px;
}

.setting-container .contact-card .new-account {
    padding: 10px 20px;
    background-color: #ffffff;
    color: #62495B;
    border: 1px solid #62495B;
    border-radius: 30px;
    cursor: pointer;
    height: 36px;
    width: 215px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.setting-container .cancel-btn {
    padding: 10px 20px;
    background-color: #ffffff;
    color: #62495B;
    border: 1px solid #62495B;
    border-radius: 30px;
    cursor: pointer;
    margin-left: 15px;
}

/* .setting-container .setting-footer {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background-color: #1B1919;
    color: #eee;
    border-top: 1px solid #eee;
}

.setting-container .footer-links {
    margin-right: 20px;
}

.setting-container .footer-links a {
    display: block;
    margin-bottom: 5px;
    color: #eee;
    text-decoration: none;
}

.setting-container .apps img {
    width: 100px;
    height: auto;
    margin-right: 10px;
} */

.setting-container .edit-button {
    background-color: white;
    color: #62495B;
    border: none;
    padding: 5px 10px;
    border-radius: 50%;
    border: 0.5px #62495B solid;
    cursor: pointer;
    margin-top: 10px;
    height: 40px;
    width: 40px;
}

.setting-container .edit-btn-profile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.setting-container .edit-btn-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
}

.setting-container .edit-button:hover {
    background-color: black;
    color: #fff;
}

.setting-container .input-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 3px;
    font-size: 1em;
}

.setting-container .update-btn {
    padding: 10px 20px;
    background-color: #62495B;
    color: #fff;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    margin-top: 20px;
}

.setting-container .phone-select {
    padding: 10px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 10px;
}

.setting-container select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}