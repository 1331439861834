* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
}

.experience-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.experience-popup .popup-inner {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
    z-index: 1010;
    position: relative;
    /* max-height: 80vh;
    overflow-y: auto; */
}

.experience-popup form {
    display: flex;
    flex-direction: column;
}

.experience-popup label {
    margin-bottom: 10px;
    font-weight: bold;
}

.experience-popup input,
.experience-popup textarea,
.experience-popup select {
    margin-top: 5px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
}

.experience-popup textarea {
    resize: none;
    height: 100px;
}

.experience-popup input[type="checkbox"] {
    margin-right: 10px;
    width: auto;
}

.experience-popup .form-buttons {
    display: flex;
    justify-content: space-between;
}

.experience-popup button {
    padding: 10px 20px;
    margin-left: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.experience-popup button[type="button"] {
    background: #ccc;
}

.experience-popup button[type="submit"] {
    background: #62495B;
    color: white;
}