* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
}

.categories-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.categories-container .header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

.categories-container .logo img {
    height: 65px;
    cursor: pointer;
}

.categories-container .content {
    width: 1120px;
    height: 450px;
    padding: 20px;
    margin-top: 50px;
    border-radius: 30px;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.categories-container h1 {
    font-size: 24px;
    margin-bottom: 10px;
}

.categories-container p {
    margin-bottom: 20px;
    color: #666;
}

.categories-container .rate-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.categories-container .rate-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.categories-container .rate-item label {
    flex: 12;
    font-weight: bold;
}

.categories-container .rate-item input {
    flex: 2;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 30px;
}

.categories-container .rate-item span {
    flex: 1;
    text-align: right;
}

.categories-container .footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
    margin-top: 80px;
}

.categories-container .footer .back-btn,
.categories-container .footer .next-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
}

.categories-container .footer .back-btn {
    background-color: #ffffff;
    color: black;
    border: 1px black solid;
}

.categories-container .footer .next-btn {
    background-color: #62495B;
    color: #fff;
}