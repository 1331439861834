* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
}

.experience-main-content {
    display: flex;
    flex-direction: column;
    width: 700px;
    height: 450px;
    overflow-y: auto;
    padding: 20px;
    margin-top: 50px;
    border-radius: 30px;
    border: 1px solid rgb(192, 192, 192);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    background-color: white;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
}

.experience-main-content .experience-container {
    width: 100%;
    margin: 0 auto;
    text-align: left;
}

.experience-main-content h2 {
    font-size: 24px;
    margin-right: 22%;
    margin-top: 2%;
}

.experience-main-content p {
    font-size: 14px;
    color: #555;
}

.experience-main-content .experience-card {
    border: 1px solid #ddd;
    width: 100%;
    padding: 20px;
    margin: 20px 0;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.experience-main-content .experience-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.experience-main-content .experience-card-header h3 {
    margin: 0;
    font-size: 18px;
}

.experience-main-content .experience-card-icons {
    font-size: 18px;
    display: flex;
    gap: 10px;
}

.experience-main-content .experience-card-icons .edit-icon,
.experience-main-content .experience-card-icons .delete-icon {
    cursor: pointer;
}

.experience-main-content .experience-card-icons .edit-icon:hover,
.experience-main-content .experience-card-icons .delete-icon:hover {
    color: #62495B;
}

.experience-main-content .experience-description {
    color: #777;
    margin-top: 10px;
}

.experience-main-content .add-experience-btn {
    background-color: #62495B;
    color: #fff;
    border: none;
    border-radius: 50%;
    font-size: 24px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: initial;
}

.experience-main-content .add-experience-btn:hover {
    background-color: #62495B;
}

.experience-main-content .save-btn {
    background-color: #62495B;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 30px;
    cursor: pointer;
}

.popup-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}
