* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
}

.title-container {
    width: 1200px;
    margin: auto;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.title-container .title-content {
    display: flex;
    flex-direction: column;
    width: 1200px;
    min-height: 540px;
    padding: 20px 30px;
    margin-top: 30px;
    border-radius: 30px;
    border: 1px rgb(192, 192, 192) solid;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.title-container .progress {
    font-size: 18px;
    background: none;
    height: fit-content;
}

.title-container h2 {
    margin: 10px 0;
    font-size: 32px;
    font-weight: 700;
    width: 50%;
}

.title-container .description {
    font-size: 15px;
    font-weight: 500;
    width: 50%;
}

.title-container .input-container {
    margin-top: 20px;
    width: 50%;
}

.title-container .input-container label {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 5px;
}

.title-container .title-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
}

.title-container .example-titles {
    margin-top: 50px;
}

.title-container .example-titles p {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 0px;
}

.title-container .example-titles li {
    font-size: 15px;
    font-weight: 600;
}


.title-container .navigation-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    width: 100%;
}

.title-container .back-button {
    padding: 10px 20px;
    border: 1px solid #62495B;
    background: none;
    color: #62495B;
    border-radius: 25px;
    cursor: pointer;
}

.title-container .next-button {
    padding: 10px 20px;
    background-color: #62495B;
    color: #fff;
    border: none;
    border-radius: 25px;
    cursor: pointer;
}