.footer-main {
    background-color: #1B1919;
    margin-top: 40px;
    padding: 20px 60px;
    border-top: 1px solid #e0e0e0;
    font-size: 14px;
    color: #fff;
    width: 100%;
}

.footer-main .footer-logo img {
    height: 65px;
}

.footer-main .footer-links {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.footer-main .footer-links a {
    color: #fff;
    text-decoration: none;
    display: block;
    margin-bottom: 5px;
}

.footer-main .footer-apps a {
    margin-right: 10px;
}

.footer-main .footer-apps img {
    width: 100px;
}

.footer-main .footer-socials {
    display: flex;
    justify-content: flex-end;
}

.footer-main .footer-socials a {
    color: #fff;
}

.footer-main .footer-socials p {
    margin-right: 20px;
}

.footer-main .footer-socials a {
    margin-right: 10px;
}

.footer-main .footer-copyright {
    margin-top: 20px;
    font-size: 12px;
}

.footer-main .footer-policy {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.footer-main .footer-policy a {
    color: #fff;
    text-decoration: none;
    font-size: 12px;
}