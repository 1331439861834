body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.client-survey7-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.client-survey7-container .header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

.client-survey7-container .logo img {
    height: 65px;
}

.client-survey7-container .survey7-contents {
    display: flex;
    justify-content: space-between;
    width: 1120px;
    min-height: 480px;
    padding: 20px;
    margin-top: 30px;
    border-radius: 30px;
    border: 1px rgb(192, 192, 192) solid;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.client-survey7-container .survey7-section {
    flex: 3;
    margin-right: 20px;
}

.client-survey7-container .survey7-section h2 {
    font-size: 28px;
    font-weight: 600;
}

.client-survey7-container .survey7-section p {
    margin: 0px 0 10px 0;
    width: 90%;
}

.client-survey7-container .testimonial-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.client-survey7-container .testimonial {
    background-color: #f8f8f8;
    padding: 20px;
    border-radius: 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
}

.client-survey7-container .testimonial-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
    background-color: #ebebeb;
}

.client-survey7-container .testimonial-detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 10px;
}

.client-survey7-container .profile-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
}

.client-survey7-container .testimonial-name {
    font-weight: bold;
    text-align: center;
}

.client-survey7-container .testimonial-detail span {
    margin-right: 7px;
    font-size: 14px;
}

.client-survey7-container .testimonial-text {
    font-size: 15px;
    color: #333;
    text-align: justify;
}

.client-survey7-container .footer {
    display: flex;
    justify-content: space-between;
    margin-top: 70px;
    width: 100%;
}

.client-survey7-container .footer .back-btn,
.client-survey7-container .footer .next-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
}

.client-survey7-container .footer .back-btn {
    background-color: #ffffff;
    color: black;
    border: 1px black solid;
}

.client-survey7-container .footer .next-btn {
    background-color: #62495B;
    color: #fff;
}

.client-survey7-container .dropzone {
    width: 50%;
    background-color: #E5E5E5;
    /* border: 2px solid #62495B; */
    padding: 20px;
    text-align: -webkit-center;
    cursor: pointer;
    border-radius: 10px;
    margin-top: 30px;
}