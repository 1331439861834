* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
  }
  
  .skill-edit-pop-up {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  
  .skill-card {
    background: white;
    padding: 20px;
    border-radius: 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 80%;
    max-width: 1120px;
    height: 80%;
    max-height: 450px;
    overflow-y: auto; 
  }
  
  .skill-card .progress {
    font-size: 14px;
    margin-bottom: 20px;
  }
  
  .skill-card .skill-card h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .skill-card .skill-card p {
    color: #555;
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .skill-card .subtext a {
    color: #62495B;
    text-decoration: none;
    font-size: 12px;
  }
  
  .skill-card .skills-section {
    margin-top: 20px;
  }
  
  .skill-card .search-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  
  .skill-card .or-text {
    display: block;
    text-align: center;
    margin: 10px 0;
    color: #999;
  }
  
  .skill-card .skills-selector {
    display: flex;
    justify-content: space-between;
  }
  
  .skill-card .categories {
    width: 30%;
    height: 202px;
    overflow-y: auto;
  }
  
  .skill-card .category-item {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 5px;
    cursor: pointer;
  }
  
  .skill-card .category-item.active {
    background-color: #62495B;
    color: white;
  }
  
  .skill-card .skills-list {
    width: 30%;
    height: 202px;
    overflow-y: auto;
  }
  
  .skill-card .skill-item {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 5px;
  }
  
  .skill-card .save-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    background-color: #62495B;
    color: #fff;
    margin-top: 4%;
    margin-left: 92%;
  }

  .skill-card .close-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    margin: 0px 95%;
}

  .skill-card .add-button{
    height: 30px;
    width: 30px;
    padding: initial;
    border: none;
    background-color: white;
  }

  .skill-card .remove-button{
    height: 30px;
    width: 30px;
    padding: initial;
    border: none;
    border-radius: 50%;
  }


  .skill-card .selected-skills {
    width: 30%;
    border: 2px dashed #62495B;
    padding: 10px;
    border-radius: 4px;
    height: 202px;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
  }
  
  .skill-card .selected-skill-item {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border-radius: 20px;
    background-color: #000;
    color: #fff;
    font-size: 14px;
  }
  
  .skill-card .selected-skill-item span {
    margin-right: 10px;
  }
  
  .skill-card .selected-skill-item .remove-button {
    height: 20px;
    width: 20px;
    padding: initial;
    border: none;
    border-radius: 50%;
    background-color: #fff;
    color: #000;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  