.client-account-container {
    width: 1150px;
    margin: 0 auto;
    font-family: 'Lato', sans-serif;
}

.client-account-container .client-account-main {
    padding: 20px;
    /* height: 450px; */
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-top: 30px;
    margin-bottom: 16%;
}

.client-account-container .client-account-main h1 {
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: 600;
}

.client-account-container .alert-box {
    padding: 15px;
    background-color: #FFB99B;
    color: #3F3F46;
    border: none;
    border-radius: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: baseline;
}

.client-account-container .alert-box a {
    color: #3F3F46;
}

.client-account-container .alert-icon {
    font-size: 20px;
    margin-right: 10px;
}

.client-account-container .alert-icon a {
    color: #62495B;
    text-decoration: underline;
}

.client-account-container .client-account-main p {
    font-size: 16px;
    margin-bottom: 20px;
}

.client-account-container .form-group {
    margin-bottom: 20px;
    margin-top: 45px;
    width: 50%;
}

.client-account-container .form-group label {
    font-size: 16px;
    margin-bottom: 5px;
    display: block;
    font-weight: 600;
}

.client-account-container .form-group input {
    width: 100%;
    padding: 10px;
    border-radius: 30px;
    border: 1px solid #ccc;
    margin-bottom: 20px;
}

.client-account-container .form-group div {
    font-size: 16px;
    color: #A3A3A3;
    font-weight: 600;
}

.client-account-container .form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 60px;
}

.client-account-container .cancel-button,
.client-account-container .create-button {
    padding: 10px 20px;
    border-radius: 30px;
    border: none;
    cursor: pointer;
    font-size: 16px;
}

.client-account-container .cancel-button {
    background: none;
    color: #62495B;
}

.client-account-container .create-button {
    background-color: #62495B;
    color: #fff;
}