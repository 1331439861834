* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
  }
  
  .onboarding-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .onboarding-container .header {
    display: flex;
    justify-content: space-between;
    width: 1120px;
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }
  
  .onboarding-container .logo {
    font-size: 24px;
    font-weight: bold;
    color: #62495B;
  }
  
  .onboarding-container .user-profile {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 0.5px black solid;
    border-radius: 30px;
    height: 50px;
    width: 150px;
  }
  
  .onboarding-container .user-name {
    font-weight: bold;
  }
  
  .onboarding-container .user-role {
    font-size: 12px;
    color: #999;
  }
  
  .onboarding-container .main-contents {
    display: flex;
    justify-content: space-between;
    width: 1120px;
    padding: 20px;
    margin-top: 100px;
    border-radius: 30px;
    border:1px rgb(192, 192, 192) solid;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  }
  
  .onboarding-container .welcome-sections {
    flex: 2;
    margin-right: 20px;
  }
  
  .onboarding-container .highlight {
    color: #62495B;
  }
  
  .onboarding-container .opportunity-list {
    list-style-type: none;
    margin-bottom: 20px;
  }
  
  .onboarding-container .opportunity-list li {
    margin-bottom: 10px;
  }
  
  .onboarding-container .continue-buttons {
    background-color:  #62495B;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 30px;
    cursor: pointer;
    margin-top: 40px;
    margin-bottom: 10px;
  }
  
  .onboarding-container .testimonial-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .onboarding-container .testimonial {
    background-color: #f8f8f8;
    padding: 20px;
    border-radius: 30px;
    /* border: 0.5px rgb(192, 191, 191) solid; */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 100%;
  }
  
  .onboarding-container .testimonial-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 5px ;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
    background-color: #ebebeb;
  }
  
  .onboarding-container .testimonial-name {
    font-weight: bold;
  }
  
  .onboarding-container .testimonial-stats span {
    margin-right: 10px;
    font-size: 12px;
    color: #999;
  }
  
  .onboarding-container .testimonial-text {
    font-size: 16px;
    color: #333;
    text-align: justify;
  }
  