* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
}

.budget-container {
    width: 1200px;
    margin: auto;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.budget-container .budget-content {
    display: flex;
    flex-direction: column;
    width: 1200px;
    min-height: 540px;
    padding: 20px 30px;
    margin-top: 30px;
    border-radius: 30px;
    border: 1px rgb(192, 192, 192) solid;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.budget-container .progress {
    font-size: 18px;
    background: none;
    height: fit-content;
}

.budget-container h2 {
    margin: 10px 0;
    font-size: 32px;
    font-weight: 700;
    width: 50%;
}

.budget-container .description {
    font-size: 15px;
    font-weight: 500;
    width: 50%;
}

.budget-container .billing-type-options {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    width: 50%;
}

.budget-container .hourly-billing h6 {
    font-size: 14px;
    font-weight: 600;
    color: #000;
    margin-top: 20px;
}

.budget-container .hourly-billing-input {
    display: flex;
    flex-direction: column;
}

.budget-container .hourly-billing-input label {
    font-size: 20px;
    font-weight: 600;
    color: #1B1919;
}

.budget-container .hourly-billing-input input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #e0e0e0;
    border-radius: 15px;
    margin-top: 10px;
}

.budget-container input[type="number"] {
    appearance: textfield;
}

.budget-container input::-webkit-outer-spin-button,
.budget-container input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.budget-container .billing-type-option {
    flex: 1;
    padding: 15px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s ease;
    height: 100px;
    display: flex;
}

.budget-container .option-icon {
    display: flex;
    align-items: center;
}

.billing-type-option.active .option-icon svg path {
    fill: white;
}

.budget-container .option-icon svg {
    fill: #62495B;
}

.budget-container .option-name {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}

.budget-container .billing-type-option.active {
    background-color: #1B1919;
    color: #fff;
}

.budget-container .billing-info {
    margin-bottom: 20px;
    color: #666;
}

.budget-container .detail {
    color: #3F3F46;
    font-size: 14px;
    font-weight: 500;
    width: 40%;
}

.budget-container .cost-estimate {
    margin-bottom: 20px;
    width: 50%;
}

.budget-container .cost-estimate label {
    font-size: 20px;
    font-weight: 600;
    color: #1B1919;
}

.budget-container .cost-estimate p {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0px;
    color: #3F3F46;
}

.budget-container .cost-estimate input {
    width: 30%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #e0e0e0;
    border-radius: 15px;
    margin-top: 10px;
}

.budget-container .navigation-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    width: 100%;
}

.budget-container .back-button {
    padding: 10px 20px;
    border: 1px solid #62495B;
    background: none;
    color: #62495B;
    border-radius: 25px;
    cursor: pointer;
}

.budget-container .next-button {
    padding: 10px 20px;
    background-color: #62495B;
    color: #fff;
    border: none;
    border-radius: 25px;
    cursor: pointer;
}