* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
}

.survey-container .options {
  display: flex;
  justify-content: space-between;
  margin-top: 55px;
}

.survey-container .option {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  border: 2px solid #ddd;
  border-radius: 30px;
  text-align: center;
  cursor: pointer;
  transition: border 0.3s;
  width: 340px;
  height: 112px;
}

.survey-container .option:hover {
  /* border-color: #6a1b9a; */
  background-color: #757575;
  color: #fff;
}

.survey-container .option input {
  margin-left: auto;
  width: auto;
}

.survey-container .icon{
  margin-left: 12px;
}

.survey-container .icon img {
  height: 60px;
  font-size: 24px;
  margin-bottom: 10px;
}

.survey-container .option .text {
  font-size: 20px;
  font-weight: bold;
  margin: 10px 50px;
}

.survey-container p {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 14px;
  font-weight: 500;
}

.survey-container .option .subtext {
  font-size: 12px;
  color: black;
}

/* ---------------------------------------- */
.survey-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.survey-container .header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.survey-container .logo {
  font-size: 24px;
  font-weight: bold;
  color: #62495B;
}

.survey-container .progress {
  font-size: 14px;
  margin-bottom: 10px;
  background-color: white;
  /* color: #666; */
}

.survey-container .main-content {
  display: flex;
  justify-content: space-between;
  width: 1120px;
  height: 456px;
  padding: 20px;
  margin-top: 50px;
  border-radius: 30px;
  border: 1px rgb(192, 192, 192) solid;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.survey-container .welcome-section {
  flex: 2;
  margin-right: 20px;
}

.survey-container .welcome-section h1 {
  font-size: 32px;
  margin-bottom: 20px;
}

.survey-container .navigation-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 80px;
}

.survey-container .back-button {
  background-color: #ffffff;
  color: black;
  border: 1px black solid;
  padding: 10px 20px;
  border-radius: 30px;
  cursor: pointer;
}

.survey-container .skip-next-buttons {
  display: flex;
  align-items: center;
}

.survey-container .skip-button {
  background-color: transparent;
  color: #62495B;
  border: none;
  padding: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.survey-container .next-button {
  background-color: #62495B;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 30px;
  cursor: pointer;
}