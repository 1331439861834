* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
}

.bio-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.bio-container .header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

.bio-container .logo img {
    height: 65px;
}

.bio-container .main-contents {
    display: flex;
    justify-content: space-between;
    width: 1120px;
    height: 450px;
    padding: 20px;
    margin-top: 50px;
    border-radius: 30px;
    border: 1px rgb(192, 192, 192) solid;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.bio-container .bio-section {
    flex: 3;
    margin-right: 20px;
}

.bio-container .bio-section h2 {
    font-size: 24px;
}

.bio-container .bio-section p {
    margin: 10px 0 20px 0;
}

.bio-container .bio-section textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 20px;
    resize: none;
}

.bio-container .char-counter {
    display: block;
    margin-top: 10px;
    font-size: 14px;
    color: #999;
}

.bio-container .testimonial-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.bio-container .testimonial {
    background-color: #f8f8f8;
    padding: 20px;
    border-radius: 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 100%;
}

.bio-container .testimonial-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
    background-color: #ebebeb;
}

.bio-container .testimonial-detail {
    margin-right: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.bio-container .profile-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
}

.bio-container .testimonial-name {
    font-weight: bold;
}

.bio-container .testimonial-stats span {
    margin-right: 10px;
    font-size: 12px;
    color: #999;
}

.bio-container .testimonial-text {
    font-size: 15px;
    color: #333;
    text-align: justify;
}

.bio-container .footer {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    width: 100%;
}

.bio-container .footer .back-btn,
.bio-container .footer .next-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
}

.bio-container .footer .back-btn {
    background-color: #ffffff;
    color: black;
    border: 1px black solid;
}

.bio-container .footer .next-btn {
    background-color: #62495B;
    color: #fff;
}