* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
}

.rate-edit-pop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.rate-content {
    width: 650px;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.rate-content h1 {
    font-size: 24px;
    margin-bottom: 10px;
}

.rate-content p {
    margin-bottom: 20px;
    color: #666;
}

.rate-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.rate-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.rate-item label {
    flex: 1;
    font-weight: bold;
}

.rate-item input {
    flex: 2;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.rate-item span {
    flex: 1;
    text-align: right;
}

.button-container {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
}

.save-btn {
    background-color: #62495B;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.rate-edit-pop-close {
    position: static;
    margin-left: 95%;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 1.5em;
}