body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.search-job {
  color: #333;
}

.search-job .adv-search {
  display: flex;
}

.search-job .adv-search input {
  border-radius: 30px;
  width: 60%;
  height: 10%;
  margin-left: 2%;
  margin-top: 5px;
  padding: 10px;
  border: 1px solid #ccc;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.search-job .adv-search p {
  margin-top: 1%;
  margin-left: 2%;
  text-align: center;
}

.search-job main {
  display: flex;
  padding: 20px;
  width: 1200px;
}

.search-job .filters {
  width: 25%;
  padding: 20px;
  background-color: #f9f9f9;
  border-right: 1px solid #eee;
}

.search-job .filter-section {
  margin-bottom: 20px;
}

.search-job .filter-section h5 {
  margin-bottom: 10px;
  font-weight: 600;
}

.search-job .filter-section select,
.search-job .filter-section input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border-radius: 15px;
}

.search-job .filter-section label {
  display: block;
  margin-bottom: 10px;
  font-weight: 600;
}

.search-job .job-results {
  width: 75%;
  padding: 20px;
}

.search-job .job-results-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
}

.search-job .job-results-header .sort-options {
  display: flex;
  align-items: center;
}

.search-job .job-results-header .sort-options button,
.search-job .job-results-header .sort-options select {
  margin-left: 10px;
  padding: 8px 12px;
  border-radius: 30px;
}

.search-job .job-item {
  padding: 20px;
  background-color: #fff;
  border: 1px solid #828282;
  margin-bottom: 20px;
  border-radius: 30px;
}

.search-job .job-heading {
  background-color: #f8f8f8;
  border-radius: 10px;
  height: 80px;
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
}

.search-job .job-item button {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}

.search-job .job-category {
  margin-top: 10px;
}

.search-job .job-item h4 {
  margin: 0;
  font-weight: 600;
  margin-bottom: 2%;
  padding-top: 1%;
}

.search-job .job-item p {
  margin: 10px 0;
}

.search-job .job-tags {
  margin: 10px 0;
}

.search-job .job-tag {
  display: inline-block;
  background-color: #E3C2FE;
  padding: 5px 10px;
  margin-right: 5px;
  border-radius: 30px;
}

.search-job .job-details {
  display: flex;
  justify-content: space-between;
  width: 65%;
  margin-bottom: 1%;
}

.search-job .job-rating {
  display: flex;
  justify-content: space-between;
}

/* ====================== */

.search-job .job-filter {
  padding: 20px;
  border: 1px solid #ccc;
  width: 300px;
}

.search-job .filter-header {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.search-job .filter-content {
  margin-top: 10px;
}

.search-job .filter-section {
  margin-bottom: 20px;
}

.search-job .hourly-rates input,
.search-job .drop-check input {
  margin: 5px 0;
  padding: 5px;
  width: 100px;
}

.search-job .drop-check label {
  display: block;
  margin: 5px 0;
  font-weight: 600;
}

.search-job label {
  display: flex;
  align-items: center;
}

.search-job input[type="checkbox"] {
  margin-right: 10px;
  width: auto;
}

.search-job input[type="number"] {
  margin-right: 10px;
  width: 60px;
  height: 22px;
}