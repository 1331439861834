* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
}

.survey2-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.survey2-container .header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

.survey2-container .logo {
    font-size: 24px;
    font-weight: bold;
    color: #62495B;
}

.survey2-container .main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1120px;
    height: 480px;
    padding: 20px;
    margin-top: 30px;
    border-radius: 30px;
    border: 1px rgb(192, 192, 192) solid;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.survey2-container .question-section {
    width: 100%;
    max-width: 1000px;
    text-align: center;
}

.survey2-container .progress {
    font-size: 14px;
    margin-bottom: 10px;
    color:#000000;
    background-color: white;
    margin-left: 12px;
}

.survey2-container h1 {
    font-size: 24px;
    margin-bottom: 10px;
    margin-right: 49%;
    font-weight: 500;
}

.survey2-container .subtext {
    font-size: 14px;
    width: 69%;
    margin-bottom: 20px;
    color: #666;
    margin-left: 10px;
    text-align: justify;
}

.survey2-container .goal-options {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.survey2-container .goal-option {
    border: 1px solid #ccc;
    border-radius: 15px;
    width: 23%;
    padding: 10px;
    cursor: pointer;
    transition: border-color 0.3s;
}

.survey2-container .goal-option input{
    width: 12px;
    margin-left: 20px;
}

.survey2-container .goal-option img {
    width: 200px;
    height: 130px;
    margin-bottom: 10px;
    border-radius: 8px;
}

.survey2-container .goal-option p {
    font-size: 20px;
    color: #333;
    font-weight: 500;
    text-align: justify;
}

.survey2-container .goal-option.selected {
    /* border-color: #6d2bf4; */
    background-color: #999;
}

.survey2-container .navigation-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 80px;
}

.survey2-container .back-button {
    background-color: #ffffff;
    color: black;
    border: 1px black solid;
    padding: 10px 20px;
    border-radius: 30px;
    cursor: pointer;
}

.survey2-container .skip-next-buttons {
    display: flex;
    align-items: center;
}

.survey2-container .skip-button {
    background-color: transparent;
    color: #62495B;
    border: none;
    padding: 10px;
    margin-right: 10px;
    cursor: pointer;
}

.survey2-container .next-button {
    background-color: #62495B;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 30px;
    cursor: pointer;
}