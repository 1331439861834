* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
}

.integration-edit-popup-main {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.integration-edit-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    padding: 20px;
    width: 600px;
    border: 2px solid black;
}

.integration-edit-popup .integration-sections {
    flex: 3;
    margin-right: 20px;
}

.integration-edit-popup .integration-sections h2 {
    font-size: 28px;
    font-weight: 600;
}

.integration-edit-popup .integration-sections p {
    margin: 10px 0 20px 0;
    width: 60%;
}

.integration-edit-popup .integration-sections textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 20px;
    resize: none;
}

.integration-edit-popup .save-btn,
.integration-edit-popup .close-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    margin: 10px 0;
}

.integration-edit-popup .save-btn {
    background-color: #62495B;
    color: #fff;
    margin-left: 90%;
}

.integration-edit-popup .integration-edit-pop-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 1.5em;
}

.integration-edit-popup .input-group input {
    width: 70%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    font-size: 1em;
    margin-bottom: 15px;
}

.integration-edit-popup form {
    margin-top: 20px;
}

.integration-edit-popup .question {
    margin-bottom: 20px;
    margin-top: 40px;
}

.integration-edit-popup .question label {
    display: block;
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 600;
}

.integration-edit-popup .question input[type="text"] {
    width: 50%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    font-size: 1em;
    margin-bottom: 15px;
}