* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
}

.job-budget-edit-popup-main {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.job-budget-edit-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    padding: 20px;
    width: 600px;
    border: 2px solid black;
}

.job-budget-edit-popup .budget-section {
    flex: 3;
    margin-right: 20px;
}

.job-budget-edit-popup .save-btn,
.job-budget-edit-popup .close-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    margin: 10px 0;
}

.job-budget-edit-popup .save-btn {
    background-color: #62495B;
    color: #fff;
    margin-left: 90%;
}

.job-budget-edit-popup .budget-edit-pop-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 1.5em;
}

/* ---------------------- */

.job-budget-edit-popup .billing-type-options {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    width: 80%;
}

.job-budget-edit-popup .hourly-billing h6 {
    font-size: 14px;
    font-weight: 600;
    color: #000;
    margin-top: 20px;
}

.job-budget-edit-popup .hourly-billing-input {
    display: flex;
    flex-direction: column;
}

.job-budget-edit-popup .hourly-billing-input label {
    font-size: 20px;
    font-weight: 600;
    color: #1B1919;
}

.job-budget-edit-popup .hourly-billing-input input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #e0e0e0;
    border-radius: 15px;
    margin-top: 10px;
}

.job-budget-edit-popup input[type="number"] {
    appearance: textfield;
}

.job-budget-edit-popup input::-webkit-outer-spin-button,
.job-budget-edit-popup input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.job-budget-edit-popup .billing-type-option {
    flex: 1;
    padding: 15px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s ease;
    height: 60px;
    display: flex;
}

.job-budget-edit-popup .option-icon {
    display: flex;
    align-items: center;
}

.job-budget-edit-popup .billing-type-option.active .option-icon svg path {
    fill: white;
}

.job-budget-edit-popup .option-icon svg {
    fill: #62495B;
}

.job-budget-edit-popup .option-name {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}

.job-budget-edit-popup .billing-type-option.active {
    background-color: #1B1919;
    color: #fff;
}

.job-budget-edit-popup .billing-info {
    margin-bottom: 20px;
    color: #666;
}

.job-budget-edit-popup .detail {
    color: #3F3F46;
    font-size: 14px;
    font-weight: 500;
    width: 40%;
}

.job-budget-edit-popup .cost-estimate {
    margin-bottom: 20px;
    width: 90%;
}

.job-budget-edit-popup .cost-estimate label {
    font-size: 20px;
    font-weight: 600;
    color: #1B1919;
}

.job-budget-edit-popup .cost-estimate p {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0px;
    color: #3F3F46;
}

.job-budget-edit-popup .cost-estimate input {
    width: 50%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #e0e0e0;
    border-radius: 15px;
    margin-top: 10px;
}